#vin {
  margin: 0;
  height: 100%;

  .input-wrap {
    margin: 0;
    border: 1px solid $fun-green-light-color; } }
#file {
  margin: 0;

  .file-loader {
    margin: 0; } }

#inp-file {
  display: none; }

.file-loader {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 56px;
  width: 100%;
  margin-bottom: 24px;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid $fun-green-light-color;
  font-size: 12px;

  .preview {
    display: flex;
    max-width: 225px;
    height: 100%;
    padding: 2px;
    border-radius: 8px;
    border: 1px solid $fun-green-light-color;

    p {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%; }

    img {
      height: auto;
      width: 35%;
      object-fit: cover;
      border-radius: 8px;
      margin-right: 12px; } } }

.custom-tab {
  margin-left: 24px;
  margin-bottom: 5px;
  height: 20px;
  width: auto;
  background: 0;
  font-size: 12px;

  &.is-active {
    border-bottom: 2px solid $fun-green-light-color; } }

.input-file {
  display: flex;
  background: 0;
  text-align: left;
  text-decoration: underline;
  width: 100%;
  svg {
    font-size: 16px;
    margin-left: 18px;
    fill: $white-color;
    stroke: $fun-green-color; } }
