.forms {
  width: 40%;
  margin-right: 25px;

  @include r(983) {
    width: 50%;
    margin-right: 35px; }
  @include r(886) {
    width: 100%;
    margin-right: 0; } }

.subscribe {
  width: 45%;
  padding-top: 15px;

  @include r(887) {
    display: none; } }


