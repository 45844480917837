html,
body {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto; }

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  padding: 0;

  .out {
    flex-grow: 1; } }

a {
  color: inherit;

  &:hover,
  &:active,
  &:focus {
    color: inherit; } }

body {
  @include r(576) {
    & .container {
      padding: 0 16px; } } }
