.personal-count-option {
  position: relative;
  width: 100%;
  padding: 1px;
  background: linear-gradient(135deg, #225C3A 0%, #10A52A 61.04%, #ACBB04 100%);
  border-radius: 16px;
  margin-bottom: 16px;

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 120px;
    padding: 24px;
    background-color: $white-color;
    border-radius: 16px;

    @include r(557) {
      align-items: start;
      justify-content: start;
      flex-direction: column;
      padding: 10px; } }

  &__price-wrap {
    text-align: right;

    @include r(557) {
      text-align: left; } }

  &__name,
  &__price-info {
    margin-bottom: 8px;
    font-size: 18px;
    color: $black-color;

    @include r(1200) {
      font-size: 14px; }

    @include r(991) {
      font-size: 12px; }

    @include r(557) {
      font-size: 12px; } }

  &__name {
    font-weight: 600; }

  &__price-info {
    font-weight: 500; }

  &__price, &__total-price {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    color: $black-color;

    @include r(1200) {
      font-size: 12px; } }

  &__total-price {
    font-size: 24px;

    @include r(1200) {
      font-size: 16px; } }

  &_basic {

    & .personal-count-option__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 90px;
      padding: 24px;
      background-color: $white-color;
      border-radius: none;

      @include r(557) {
        align-items: start;
        justify-content: start;
        flex-direction: column;
        padding: 5px; } }

    & .personal-count-option__price-wrap {
      display: flex;
      align-items: center;
      width: 30%;

      @include r(557) {
        width: 50%; } }

    & .personal-count-option__name-wrap {
      width: 55%; }

    & .personal-count-option__name {
      font-size: 18px;
      font-weight: 500;

      @include r(1200) {
        font-size: 14px; }

      @include r(991) {
        font-size: 12px; }

      @include r(557) {
        font-size: 12px; } }

    & .personal-count-option__price {
      font-size: 24px;
      font-weight: 600;
      color: $black-color;
      text-align: right; }


    & .personal-count-option__total-price {
        text-align: right;

        @include r(1200) {
          width: 100%;
          text-align: left;
          font-size: 16px; } } } }
