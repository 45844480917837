.accordion {
  max-width: 1048px;
  max-height: 1100px;

  &__btn-count {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 64px;
    border-bottom: 1px solid $silver-color;
    background: $white-color;

    & svg {
      fill: $black-color;
      font-size: 24px;
      transition: transform 300ms ease-in-out; }

    &.is-open {
      border: 0;

      & + .accordion__drop {
        display: block;
        opacity: 1; }
      & svg {
        transform: rotate(90deg);
        fill: $fun-green-color; }

      & span {
        color: $fun-green-color; } } }

  &__btn-count-text {
    font-size: 18px;
    line-height: 1.2em;
    font-weight: 500;
    letter-spacing: -0.02em;
    color: $black-color;

    @include r(991) {
      font-size: 14px; } }

  &__drop {
    padding: 1px;
    display: none;
    width: 100%;
    height: 100%;
    background: linear-gradient(99.78deg,#225c3a 0%,#10a52a 59.65%,#acbb04 116.87%);
    border-radius: 16px;
    opacity: 0;
    transition: opacity 300ms;

    &-content {
      width: 100%;
      height: 100%;
      padding: 32px;
      border-radius: 16px;
      background: $white-color; } }

  &__additional-options {
    margin-left: 24px;

    &__title {
      font-size: 18px;
      font-weight: 700; }

    &__text {
      font-size: 14px;
      font-weight: 600; } } }

