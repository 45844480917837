.entry {
  padding: 242px 0 396px;
  @include r(992) {
    padding: 190px 0 250px; }
  @include r(576) {
    padding: 124px 0 99px; }

  &_sms {
    padding: 242px 0 444px;
    @include r(992) {
      padding: 190px 0 250px; }
    @include r(576) {
      padding: 124px 0 121px; } }

  &__content {
    margin: 0 auto;
    max-width: 631px;
    @include r(576) {
      max-width: 343px; } }

  &__title {
    margin-bottom: 40px;
    text-align: center;
    @include fMontserratAlt;
    font-size: 40px;
    line-height: 1.1em;
    font-weight: 600;
    color: $midnight-color;
    @include r(768) {
      margin-bottom: 24px;
      font-size: 24px; }
    @include r(576) {
      font-size: 18px; } }

  .entry-phone-form,
  .entry-sms-form {
    &__input-wrap {
      display: flex;
      margin: 0 auto 40px;
      width: 100%;
      max-width: 372px;
      border: 1px solid $nobel-color;
      border-radius: 8px;
      @include r(768) {
        margin: 0 auto 24px; }
      &:focus-within {
        border-color: $fun-green-light-color;
        box-shadow: 4px 4px 10px rgba(0, 149, 51, 0.1); } }

    &__input {
      padding: 19px 23px;
      font-size: 18px;
      line-height: 1em;
      font-weight: 600;
      color: $black-color;
      border: none;
      border-radius: 8px;
      &:focus {
        box-shadow: none; } }

    &__submit-button {
      margin: 0 auto;
      max-width: 542px;
      @include r(576) {
        font-size: 18px; } } } }
