.receiving-page {
  padding: 178px 0 48px;
  @include r(992) {
    padding: 132px 0 48px; }
  @include r(576) {
    padding: 116px 0 60px; }

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include r(992) {
      flex-direction: column; } }
  & .content {
    &__item {
      max-width: 46%;
      @include r(992) {
        max-width: 100%;
        &:not(:last-child) {
          margin-bottom: 0; } }

      &:nth-child(1) {
        @include r(992) {
          order: 2; } }
      &:nth-child(2) {
        @include r(992) {
          order: 1;
          margin-bottom: 32px; } } }

    &__header {
      font-size: 40px;
      font-weight: 600;
      line-height: 48px;
      margin-bottom: 40px;
      @include r(992) {
        text-align: center;
        font-size: 32px;
        margin-bottom: 16px; } } } }
