.button_close {
	@include pseudo;
	position: absolute;
	top: 24px;
	right: 24px;
	width: 30px;
	height: 30px;
	cursor: pointer;

	@include r(1200) {
		top: 16px;
		right: 16px; }

	&::before,
	&::after {
		top: 43%;
		left: 3%;
		width: 90%;
		height: 2px;
		background-color: $black-color; }

	&::before {
		transform: rotate(45deg); }

	&::after {
		transform: rotate(-45deg); } }
