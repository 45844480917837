.green-gradient-border {
  background: linear-gradient(99.78deg, #225C3A 0%, #10A52A 59.65%, #ACBB04 116.87%);
  padding: 3px;
  border-radius: 16px;
  overflow: hidden;
  & > div {
    background: $white-color;
    border-radius: 13px; } }
.orange-gradient-border {
  background: linear-gradient(98.77deg, #FF9E44 -11.75%, #DC0854 103.61%);
  padding: 3px;
  border-radius: 8px;

  width: fit-content;
  box-shadow: 2px 2px 10px 0px rgba(238, 109, 125, 0.2);
  & > div {
    background: $white-color;
    border-radius: 5px; }
  & > a {
    background: $white-color;
    border-radius: 5px; } }
.orange-gradinted-button {
  display: inline-block;
  min-width: 255px;
  padding: 10px 0;
  text-decoration: none;
  text-align: center;
  line-height: 1.36em;
  letter-spacing: -.02em;
  border-radius: 5px;
  transition: background 300ms, color 100ms;
  font-size: 18px;
  color: $black-color;
  font-weight: 700;
  &:hover {
    background: linear-gradient(98.77deg, #FF9E44 -11.75%, #DC0854 103.61%);
    color: $white-color; } }


.your-options {
  @include fMontserrat;
  letter-spacing: -2%;
  line-height: 1.24em;
  & .basic-option {
    &__content {
      padding: 24px; }
    &__info {
      background: linear-gradient(99.78deg, #225C3A 0%, #10A52A 59.65%, #ACBB04 116.87%);
      padding: 18px 48px;
      color: $white-color;
      border-radius: 16px; }
    &__title {
      margin-bottom: 7px;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: -0.02em;
      font-weight: 700; }
    &__descr {
      font-size: 18px;
      letter-spacing: -0.02em;
      font-weight: 400;
      margin: 4px 0;
      line-height: 22px; }
    &__price {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 29px;
      letter-spacing: -0.02em;
      margin-top: 8px; } }
  &__to-settings {
    text-align: center; }
  &__text {
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    margin-top: 24px; }
  &__button {
    margin: 16px auto 0; }

  .additional-options {
    & {
      margin-top: 32px; }
    &__info {
      display: flex;
      flex-direction: row;
      align-items: center;
      max-width: 532px;
      margin-bottom: 24px; }
    &__image {
      width: 67px;
      margin-right: 13px; }
    &__header-title {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 29px; }
    &__header-text {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      margin-top: 8px; }

    & .additional-option {
      position: relative;
      width: 100%;
      border: 3px solid $nobel-color;
      border-radius: 16px;
      display: flex;
      flex-direction: row;
      padding: 22px 80px 21px 80px;
      box-sizing: border-box;
      &:not(:last-child) {
        margin-bottom: 16px; }
      @include r(992) {
        padding: 16px 16px 16px 101px; }

      &__image {
        position: absolute;
        left: -52.5px;
        top: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 105px;
        height: 105px;
        background: linear-gradient(135deg, #225C3A 0%, #10A52A 51.04%, #ACBB04 100%);
        border-radius: 50%;
        transform: translateY(-50%);
        & svg {
          fill: $white-color;
          font-size: 74px; }
        @include r(992) {
          top: 50%;
          left: 16px;
          width: 74px;
          height: 74px;
          transform: translateY(-50%); } }

      &__title {
        color: $mine-shaft;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: -0.02em;
        margin-bottom: 4px; }

      &__description {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        color: $shuttle-gray;
        margin-bottom: 4px; }

      &__price {
        margin: 0;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 29px;
        color: $shuttle-gray; }
      &.is-active {
        background: linear-gradient(99.78deg, #225C3A 0%, #10A52A 59.65%, #ACBB04 116.87%);
        border: none;
        .additional-option {
          &__title {
            color: $white-color; }
          &__description {
            color: $white-color; }
          &__price {
            color: $white-color; } } } } } }
