.cost {
  padding: 45px 0 55px;
  @include r(768) {
    padding: 32px 0 40px; }

  &__content {
    display: flex;
    justify-content: space-between;

    .vehicle-form {
      margin-right: 126px; }

    @include r(1400) {
      .vehicle-form,
      .calculate-cost {
        margin-right: 80px; } }

    @include r(992) {
      .vehicle-form,
      .calculate-cost {
        margin-right: 30px; } }

    @include r(768) {
      .vehicle-form,
      .calculate-cost {
        margin-right: 0; } } } }
