.partners {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  max-width: 415px;
  height: fit-content;

  & a {
    text-decoration: none;
    & img {
      width: 63px;
      height: 57px;
      object-fit: contain;
      object-position: center;
      @include r(576) {
        width: 45px;
        height: 40px; } }
    & svg {
      fill: $white-color; }

    &:nth-child(1) {
      & svg {
        font-size: 41px; } }

    &:nth-child(2) {
      & svg {
        font-size: 57px;
        fill: transparent;
        stroke: $white-color; } }

    &:nth-child(3) {
      & svg {
        font-size: 35px; } } } }
