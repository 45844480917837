.personal-cabinet {
	@include r(575) {
		padding: 10px; }

	&__navigation {

		.navigation-tabs {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-direction: column;

			@include r(768) {
      	flex-direction: row; }

			&__tab {
				width: 100%;
				margin-bottom: 24px;
				min-width: 100%;
				font-size: 20px;
				line-height: 1;

				@include r(768) {
					margin-bottom: 0; }

				@include r(1200) {
					padding: 0 8px;
					width: 100%;
					min-width: unset;
					height: 37px; }

				&:not(:last-child) {

					@include r(768) {
						margin-right: 4px; } }

				span {
					@include r(1200) {
						font-weight: 600;
						font-size: 12px;
						line-height: 16px; } }

				&.is-active {
					color: $white-color;

					&::before {
						background-color: transparent; } }

				&::before {
					top: 1px;
					left: 1px;
					width: calc(100% - 2px);
					height: calc(100% - 2px); } } } } }
