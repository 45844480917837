.icon-additional {
  width: 0.96em;
  height: 1em;
  fill: initial;
}
.icon-arrow-down {
  width: 1.83em;
  height: 1em;
  fill: initial;
}
.icon-arrow-right {
  width: 0.56em;
  height: 1em;
  fill: initial;
}
.icon-basic-option {
  width: 1.01em;
  height: 1em;
  fill: initial;
}
.icon-car-damage {
  width: 1.57em;
  height: 1em;
  fill: initial;
}
.icon-car-theft {
  width: 1.01em;
  height: 1em;
  fill: initial;
}
.icon-car {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-cars {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-car_active {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-car_disabled {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-crash {
  width: 1.06em;
  height: 1em;
  fill: initial;
}
.icon-direct-download {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-euroins-logo {
  width: 3.74em;
  height: 1em;
  fill: initial;
}
.icon-expand {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-expand_disabled {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-facebook {
  width: 0.53em;
  height: 1em;
  fill: initial;
}
.icon-oranta-logo {
  width: 2.9em;
  height: 1em;
  fill: initial;
}
.icon-plus {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-spare-logo {
  width: 1.11em;
  height: 1em;
  fill: initial;
}
.icon-telegram {
  width: 1.08em;
  height: 1em;
  fill: initial;
}
.icon-thief-parking {
  width: 1.22em;
  height: 1em;
  fill: initial;
}
.icon-thief {
  width: 1.06em;
  height: 1em;
  fill: initial;
}
.icon-triangle-arrow {
  width: 1.44em;
  height: 1em;
  fill: initial;
}
.icon-viber {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-whatsapp {
  width: 0.93em;
  height: 1em;
  fill: initial;
}
