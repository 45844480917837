.insurance-company {
  margin-top: 24px;
  margin-bottom: 64px;

  @include r(1200) {
    margin-bottom: 44px; }
  .sponsors {
    position: relative;
    height: 74px;
    width: 25%;
    margin-right: 8px;
    background: 0;
    border: 1px solid $silver-color;
    border-radius: 8px;
    z-index: 100;
    &.active {
      border: 3px solid $fun-green-light-color; }
    svg {
      height: 50%;
      width: 100%;
      pointer-events: none; }

    &_img {
      img {
        width: 85%;
        height: 76%;
        object-fit: contain;
        object-position: center;
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(307deg) brightness(104%) contrast(104%);
        pointer-events: none; } } } }
