.socials {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 152px;

  .social {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border: 1px solid $white-color;
    border-radius: 6px;
    text-decoration: none;
    & svg {
      fill: $white-color; } } }
