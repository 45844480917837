@charset "UTF-8";
.button_gradient, .button_close, .options__data, .sidebar-wrap__sidebar {
  position: relative;
}

.button_gradient:before, .button_close:before, .options__data:before, .sidebar-wrap__sidebar:before, .button_gradient:after, .button_close:after, .options__data:after, .sidebar-wrap__sidebar:after {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
}

.icon-additional {
  width: 0.96em;
  height: 1em;
  fill: initial;
}

.icon-arrow-down {
  width: 1.83em;
  height: 1em;
  fill: initial;
}

.icon-arrow-right {
  width: 0.56em;
  height: 1em;
  fill: initial;
}

.icon-basic-option {
  width: 1.01em;
  height: 1em;
  fill: initial;
}

.icon-car-damage {
  width: 1.57em;
  height: 1em;
  fill: initial;
}

.icon-car-theft {
  width: 1.01em;
  height: 1em;
  fill: initial;
}

.icon-car {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-cars {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-car_active {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-car_disabled {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-crash {
  width: 1.06em;
  height: 1em;
  fill: initial;
}

.icon-direct-download {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-euroins-logo {
  width: 3.74em;
  height: 1em;
  fill: initial;
}

.icon-expand {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-expand_disabled {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-facebook {
  width: 0.53em;
  height: 1em;
  fill: initial;
}

.icon-oranta-logo {
  width: 2.9em;
  height: 1em;
  fill: initial;
}

.icon-plus {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-spare-logo {
  width: 1.11em;
  height: 1em;
  fill: initial;
}

.icon-telegram {
  width: 1.08em;
  height: 1em;
  fill: initial;
}

.icon-thief-parking {
  width: 1.22em;
  height: 1em;
  fill: initial;
}

.icon-thief {
  width: 1.06em;
  height: 1em;
  fill: initial;
}

.icon-triangle-arrow {
  width: 1.44em;
  height: 1em;
  fill: initial;
}

.icon-viber {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-whatsapp {
  width: 0.93em;
  height: 1em;
  fill: initial;
}

.icon-additional {
  width: 0.96em;
  height: 1em;
  fill: initial;
}

.icon-arrow-down {
  width: 1.83em;
  height: 1em;
  fill: initial;
}

.icon-arrow-right {
  width: 0.56em;
  height: 1em;
  fill: initial;
}

.icon-basic-option {
  width: 1.01em;
  height: 1em;
  fill: initial;
}

.icon-car-damage {
  width: 1.57em;
  height: 1em;
  fill: initial;
}

.icon-car-theft {
  width: 1.01em;
  height: 1em;
  fill: initial;
}

.icon-car {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-cars {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-car_active {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-car_disabled {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-crash {
  width: 1.06em;
  height: 1em;
  fill: initial;
}

.icon-direct-download {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-euroins-logo {
  width: 3.74em;
  height: 1em;
  fill: initial;
}

.icon-expand {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-expand_disabled {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-facebook {
  width: 0.53em;
  height: 1em;
  fill: initial;
}

.icon-oranta-logo {
  width: 2.9em;
  height: 1em;
  fill: initial;
}

.icon-plus {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-spare-logo {
  width: 1.11em;
  height: 1em;
  fill: initial;
}

.icon-telegram {
  width: 1.08em;
  height: 1em;
  fill: initial;
}

.icon-thief-parking {
  width: 1.22em;
  height: 1em;
  fill: initial;
}

.icon-thief {
  width: 1.06em;
  height: 1em;
  fill: initial;
}

.icon-triangle-arrow {
  width: 1.44em;
  height: 1em;
  fill: initial;
}

.icon-viber {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-whatsapp {
  width: 0.93em;
  height: 1em;
  fill: initial;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5,
h6, p, blockquote, pre, a, abbr, acronym, address, big,
cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small,
strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt,
dd, ol, ul, li, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
embed, figure, figcaption, footer, header, hgroup, menu, nav,
output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

img, fieldset, a img {
  border: none;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  -webkit-appearance: none;
}

input[type="submit"],
button {
  cursor: pointer;
}

input[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

textarea {
  overflow: auto;
}

input, button {
  margin: 0;
  padding: 0;
  border: 0;
}

div, input, textarea, select, button,
h1, h2, h3, h4, h5, h6, a, span, a:focus {
  outline: none;
}

ul, ol {
  list-style-type: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

@media (prefers-reduced-motion: reduce) {
  * {
    transition: none !important;
  }
}

@font-face {
  font-family: "Montserrat";
  src: url("/fonts/Montserrat-Light.ttf") format("ttf");
  src: url("/fonts/Montserrat-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: "normal";
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/fonts/Montserrat-Regular.ttf") format("ttf");
  src: url("/fonts/Montserrat-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: "normal";
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/fonts/Montserrat-Medium.ttf") format("ttf");
  src: url("/fonts/Montserrat-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: "normal";
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/fonts/Montserrat-SemiBold.ttf") format("ttf");
  src: url("/fonts/Montserrat-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: "normal";
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/fonts/Montserrat-Bold.ttf") format("ttf");
  src: url("/fonts/Montserrat-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: "normal";
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("/fonts/Montserrat-ExtraBold.ttf") format("ttf");
  src: url("/fonts/Montserrat-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: "normal";
  font-display: swap;
}

@font-face {
  font-family: "MontserratAlternates";
  src: url("/fonts/MontserratAlternates-Light.ttf") format("ttf");
  src: url("/fonts/MontserratAlternates-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: "normal";
  font-display: swap;
}

@font-face {
  font-family: "MontserratAlternates";
  src: url("/fonts/MontserratAlternates-Regular.ttf") format("ttf");
  src: url("/fonts/MontserratAlternates-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: "normal";
  font-display: swap;
}

@font-face {
  font-family: "MontserratAlternates";
  src: url("/fonts/MontserratAlternates-Medium.ttf") format("ttf");
  src: url("/fonts/MontserratAlternates-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: "normal";
  font-display: swap;
}

@font-face {
  font-family: "MontserratAlternates";
  src: url("/fonts/MontserratAlternates-SemiBold.ttf") format("ttf");
  src: url("/fonts/MontserratAlternates-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: "normal";
  font-display: swap;
}

@font-face {
  font-family: "MontserratAlternates";
  src: url("/fonts/MontserratAlternates-Bold.ttf") format("ttf");
  src: url("/fonts/MontserratAlternates-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: "normal";
  font-display: swap;
}

@font-face {
  font-family: "MontserratAlternates";
  src: url("/fonts/MontserratAlternates-ExtraBold.ttf") format("ttf");
  src: url("/fonts/MontserratAlternates-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: "normal";
  font-display: swap;
}

body {
  font-family: 'Montserrat', 'Arial', sans-serif;
}

* {
  font-family: 'Montserrat', 'Arial', sans-serif;
}

.rangeSlider,
.rangeSlider__fill {
  display: block;
  border-radius: 10px;
}

.rangeSlider {
  position: relative;
  top: 100%;
  transform: translateY(-50%);
  background: transparent;
}

.rangeSlider__horizontal {
  height: 5px;
  width: 100%;
}

.rangeSlider__vertical {
  height: 100%;
  width: 20px;
}

.rangeSlider--disabled {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  opacity: 0.4;
}

.rangeSlider__fill {
  background: #009533;
  position: absolute;
}

.rangeSlider__fill__horizontal {
  height: 100%;
  top: 0;
  left: 0;
}

.rangeSlider__fill__vertical {
  width: 100%;
  bottom: 0;
  left: 0;
}

.rangeSlider__handle {
  border: 1px solid #009533;
  cursor: pointer;
  display: inline-block;
  width: 18px;
  height: 18px;
  position: absolute;
  background: #ffffff;
  border-radius: 50%;
}

.rangeSlider__handle:after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #009533;
  border-radius: 50%;
}

.rangeSlider__handle:active {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.12));
}

.rangeSlider__handle__horizontal {
  top: -7px;
}

.rangeSlider__handle__vertical {
  left: -10px;
  bottom: 0;
}

input[type="range"]:focus + .rangeSlider .rangeSlider__handle {
  box-shadow: 0 0 8px rgba(142, 68, 173, 0.9);
}

.rangeSlider__buffer {
  position: absolute;
  top: 3px;
  height: 14px;
  background: #2c3e50;
  border-radius: 10px;
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em;
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline;
  list-style: none;
  padding: 0;
}

.select2-container .select2-selection--multiple .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em;
}

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  margin-left: 5px;
  padding: 0;
  max-width: 100%;
  resize: none;
  height: 18px;
  vertical-align: bottom;
  font-family: sans-serif;
  overflow: hidden;
  word-break: keep-all;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-results__option--selectable {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  height: 26px;
  margin-right: 20px;
  padding-right: 0px;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  padding-bottom: 5px;
  padding-right: 5px;
  position: relative;
}

.select2-container--default .select2-selection--multiple.select2-selection--clearable {
  padding-right: 25px;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  font-weight: bold;
  height: 20px;
  margin-right: 10px;
  margin-top: 5px;
  position: absolute;
  right: 0;
  padding: 1px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0;
  padding-left: 20px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  border: none;
  border-right: 1px solid #aaa;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #999;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0 4px;
  position: absolute;
  left: 0;
  top: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover, .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus {
  background-color: #f1f1f1;
  color: #333;
  outline: none;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  border-left: 1px solid #aaa;
  border-right: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__clear {
  float: left;
  margin-left: 10px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__option--group {
  padding: 0;
}

.select2-container--default .select2-results__option--disabled {
  color: #999;
}

.select2-container--default .select2-results__option--selected {
  background-color: #ddd;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #5897fb;
  color: white;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, #fff 50%, #eee 100%);
  background-image: -o-linear-gradient(top, #fff 50%, #eee 100%);
  background-image: linear-gradient(to bottom, #fff 50%, #eee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  height: 26px;
  margin-right: 20px;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eee 50%, #ccc 100%);
  background-image: -o-linear-gradient(top, #eee 50%, #ccc 100%);
  background-image: linear-gradient(to bottom, #eee 50%, #ccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0);
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #fff 0%, #eee 50%);
  background-image: -o-linear-gradient(top, #fff 0%, #eee 50%);
  background-image: linear-gradient(to bottom, #fff 0%, #eee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eee 50%, #fff 100%);
  background-image: -o-linear-gradient(top, #eee 50%, #fff 100%);
  background-image: linear-gradient(to bottom, #eee 50%, #fff 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
  padding-bottom: 5px;
  padding-right: 5px;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  border: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #888;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0 4px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
  outline: none;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}

.select2-container--classic .select2-dropdown {
  background-color: #fff;
  border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option--group {
  padding: 0;
}

.select2-container--classic .select2-results__option--disabled {
  color: grey;
}

.select2-container--classic .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #3875d7;
  color: #fff;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

html,
body {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

body .out {
  flex-grow: 1;
}

a {
  color: inherit;
}

a:hover, a:active, a:focus {
  color: inherit;
}

@media only screen and (max-width: 576px) {
  body .container {
    padding: 0 16px;
  }
}

.header-main {
  width: 100%;
  height: 438px;
  background-image: linear-gradient(95.29deg, rgba(2, 110, 38, 0.74) 0%, rgba(2, 110, 38, 0) 99.98%, rgba(255, 255, 255, 0.74) 99.99%), url("../../img/header/busy-traffic.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.header-main__content {
  padding-top: 48px;
}

.header-main .logo {
  margin-left: 35px;
  margin: 0 0 30px 35px;
}

.header-main__price {
  margin: 0 0 10px 35px;
  font-size: 36px;
  line-height: 1.36em;
  letter-spacing: 0.02em;
  color: #ffffff;
  font-weight: 700;
}

.header-main__benefits {
  margin-left: 35px;
  list-style-type: numeric;
  max-width: 577px;
}

.header-main__benefit {
  font-size: 18px;
  line-height: 1.67em;
  letter-spacing: 0.02em;
  color: #ffffff;
  font-weight: 700;
}

.companies-logos a svg {
  fill: #ffffff;
  font-size: 50px;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 40px 0;
  min-height: 130px;
  background: linear-gradient(95.29deg, rgba(17, 114, 1, 0.963488) 0%, rgba(21, 160, 45, 0.974982) 51.04%, #009962 100%);
}

.header__content {
  display: flex;
  justify-content: space-between;
}

.header__left {
  display: flex;
  justify-content: space-between;
  margin-right: 15px;
  width: 100%;
  max-width: 725px;
  max-height: 50px;
}

.header__right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 424px;
}

@media only screen and (max-width: 1400px) {
  .header .button {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1200px) {
  .header .logo {
    font-size: 24px;
  }
  .header__left {
    max-width: 350px;
  }
  .header__left .button:nth-child(3) {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  .header {
    display: flex;
    align-items: center;
    padding: 30px 0;
    min-height: 100px;
  }
  .header__left {
    max-width: unset;
  }
  .header__right {
    display: none;
  }
  .header .logo {
    font-size: 24px;
  }
  .header .button:nth-child(2) {
    padding: 7px 13px;
    font-size: 16px;
    line-height: 1.22em;
  }
}

@media only screen and (max-width: 576px) {
  .header {
    padding: 24px 0;
    min-height: 84px;
  }
  .header .logo {
    font-size: 18px;
  }
}

.header_main {
  position: absolute;
  background: transparent;
}

.logo {
  display: flex;
  text-decoration: none;
  align-items: center;
  font-family: 'MontserratAlternates', 'Arial', sans-serif;
  font-size: 36px;
  line-height: 1.22em;
  font-weight: 700;
  color: #ffffff;
}

.no-touch .logo:hover {
  color: #ffffff;
}

.contacts {
  text-align: right;
  max-width: 157px;
}

.contacts__tel,
.contacts p {
  margin: 0;
  font-family: 'Montserrat', 'Arial', sans-serif;
  font-size: 16px;
  line-height: 1.22em;
  letter-spacing: -0.02em;
  font-weight: 500;
  color: #ffffff;
}

.contacts__tel {
  text-decoration: none;
  font-weight: 700;
}

@media only screen and (max-width: 576px) {
  .contacts__tel,
  .contacts p {
    font-size: 14px;
  }
}

.socials {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 152px;
}

.socials .social {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: 1px solid #ffffff;
  border-radius: 6px;
  text-decoration: none;
}

.socials .social svg {
  fill: #ffffff;
}

.language {
  position: relative;
}

.language__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 51px;
  height: 33px;
  font-family: 'Montserrat', 'Arial', sans-serif;
  font-size: 16px;
  line-height: 1.22em;
  letter-spacing: -0.02em;
  font-weight: 600;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 6px;
  background: none;
}

.language__arrow {
  display: flex;
  margin-left: 4px;
  width: 12px;
}

.language__arrow svg {
  fill: transparent;
  stroke: #ffffff;
}

.language__list {
  display: none;
  position: absolute;
  top: 38px;
  left: 0;
  z-index: 100;
  padding: 0;
  max-width: 51px;
  border: 1px solid #ffffff;
  border-radius: 6px;
  background-color: #009962;
}

.language__list .is-active {
  text-decoration: underline;
}

.language__item {
  text-align: center;
}

.language__item button {
  color: #ffffff;
  border: none;
}

.button {
  display: inline-block;
  min-width: 255px;
  padding: 11px 0 10px;
  text-decoration: none;
  text-align: center;
  font-size: 18px;
  line-height: 1.36em;
  letter-spacing: -0.02em;
  color: #00122D;
  font-weight: 700;
  background-color: #FFB800;
  border-radius: 3px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  border: 2px solid #FFB800;
  transition: background-color 300ms, color 300ms;
}

.no-touch .button:hover {
  background-color: #ffffff;
  color: black;
}

.button_header, .button_footer {
  padding: 12px 9px;
  min-width: 121px;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 10px;
  background: none;
  box-shadow: none;
}

.no-touch .button_header:hover, .no-touch .button_footer:hover {
  background-color: #ffffff;
  color: #016C25;
}

.button_gradient {
  z-index: 1;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  outline: none;
  color: inherit;
  border-radius: 8px;
}

.button_gradient span {
  position: relative;
  z-index: 3;
  font-weight: bold;
  line-height: 1;
  text-align: center;
}

.button_gradient_green {
  background: linear-gradient(99.78deg, #225C3A 0%, #10A52A 59.65%, #ACBB04 116.87%);
  box-shadow: 4px 4px 10px rgba(0, 149, 51, 0.1);
}

.button_gradient_green span {
  color: #000000;
}

.button_gradient_green.is-active span {
  color: #ffffff;
}

.button_gradient_green-borders {
  background: linear-gradient(99.78deg, #225C3A 0%, #10A52A 59.65%, #ACBB04 116.87%);
  box-shadow: 4px 4px 10px rgba(0, 149, 51, 0.1);
}

.button_gradient_green-borders span {
  color: #000000;
}

.button_gradient_green-borders::before {
  top: 3px;
  left: 3px;
  z-index: 2;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  border-radius: 8px;
  background-color: #ffffff;
  transition: all .3s ease-in-out;
}

.button_gradient_green-borders.is-active span, .button_gradient_green-borders:hover span {
  color: #ffffff;
}

.button_gradient_green-borders.is-active::before, .button_gradient_green-borders:hover::before {
  background-color: transparent;
}

.button_gradient_red {
  background: linear-gradient(99.78deg, #FF9E44 0%, #DC0854 100%);
  box-shadow: 2px 2px 10px rgba(238, 109, 125, 0.2);
}

.button_gradient_red.is-active span, .button_gradient_red:hover span {
  color: #000000;
}

.button_gradient_red.is-active::before, .button_gradient_red:hover::before {
  opacity: 1;
}

.button_gradient_red span {
  color: #ffffff;
}

.button_gradient_red::before {
  top: 3px;
  left: 3px;
  z-index: 2;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  border-radius: 8px;
  background-color: #ffffff;
  opacity: 0;
  transition: all .3s ease-in-out;
}

.button_gradient_red-borders {
  background: linear-gradient(99.78deg, #FF9E44 0%, #DC0854 100%);
  box-shadow: 2px 2px 10px rgba(238, 109, 125, 0.2);
}

.button_gradient_red-borders::before {
  top: 3px;
  left: 3px;
  z-index: 2;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  border-radius: 8px;
  background-color: #ffffff;
  transition: all .3s ease-in-out;
}

.button_gradient_red-borders.is-active span, .button_gradient_red-borders:hover span {
  color: #ffffff;
}

.button_gradient_red-borders.is-active::before, .button_gradient_red-borders:hover::before {
  background-color: transparent;
}

.button_gradient_m {
  padding: 0 20px;
  height: 56px;
}

@media only screen and (max-width: 1200px) {
  .button_gradient_m {
    height: 62px;
  }
}

.button_gradient_m span {
  font-size: 18px;
}

.button_gradient_s {
  padding: 0 16px;
  height: 33px;
}

.button_gradient_s::before {
  top: 1px;
  left: 1px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
}

.button_gradient_s span {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.02em;
}

.button_close {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {
  .button_close {
    top: 16px;
    right: 16px;
  }
}

.button_close::before, .button_close::after {
  top: 43%;
  left: 3%;
  width: 90%;
  height: 2px;
  background-color: #000000;
}

.button_close::before {
  transform: rotate(45deg);
}

.button_close::after {
  transform: rotate(-45deg);
}

.receiving-page {
  padding: 178px 0 48px;
}

@media only screen and (max-width: 992px) {
  .receiving-page {
    padding: 132px 0 48px;
  }
}

@media only screen and (max-width: 576px) {
  .receiving-page {
    padding: 116px 0 60px;
  }
}

.receiving-page__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media only screen and (max-width: 992px) {
  .receiving-page__content {
    flex-direction: column;
  }
}

.receiving-page .content__item {
  max-width: 46%;
}

@media only screen and (max-width: 992px) {
  .receiving-page .content__item {
    max-width: 100%;
  }
  .receiving-page .content__item:not(:last-child) {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 992px) {
  .receiving-page .content__item:nth-child(1) {
    order: 2;
  }
}

@media only screen and (max-width: 992px) {
  .receiving-page .content__item:nth-child(2) {
    order: 1;
    margin-bottom: 32px;
  }
}

.receiving-page .content__header {
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  margin-bottom: 40px;
}

@media only screen and (max-width: 992px) {
  .receiving-page .content__header {
    text-align: center;
    font-size: 32px;
    margin-bottom: 16px;
  }
}

.receive-your-kasko .content__header {
  text-align: center;
  color: #00122D;
}

@media only screen and (max-width: 992px) {
  .receive-your-kasko .content__header {
    text-align: center;
    margin-bottom: 16px;
    font-size: 32px;
  }
}

@media only screen and (max-width: 768px) {
  .receive-your-kasko .content__header {
    font-size: 24px;
    margin-bottom: 8px;
  }
}

.receive-your-kasko__text {
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.02em;
  color: #00122D;
}

@media only screen and (max-width: 992px) {
  .receive-your-kasko__text {
    max-width: 450px;
    margin: 0 auto;
    font-size: 18px;
  }
}

.receive-your-kasko__button {
  margin: 32px auto 0;
}

@media only screen and (max-width: 992px) {
  .receive-your-kasko__button {
    margin: 16px auto 0;
  }
}

.green-gradient-border {
  background: linear-gradient(99.78deg, #225C3A 0%, #10A52A 59.65%, #ACBB04 116.87%);
  padding: 3px;
  border-radius: 16px;
  overflow: hidden;
}

.green-gradient-border > div {
  background: #ffffff;
  border-radius: 13px;
}

.orange-gradient-border {
  background: linear-gradient(98.77deg, #FF9E44 -11.75%, #DC0854 103.61%);
  padding: 3px;
  border-radius: 8px;
  width: fit-content;
  box-shadow: 2px 2px 10px 0px rgba(238, 109, 125, 0.2);
}

.orange-gradient-border > div {
  background: #ffffff;
  border-radius: 5px;
}

.orange-gradient-border > a {
  background: #ffffff;
  border-radius: 5px;
}

.orange-gradinted-button {
  display: inline-block;
  min-width: 255px;
  padding: 10px 0;
  text-decoration: none;
  text-align: center;
  line-height: 1.36em;
  letter-spacing: -.02em;
  border-radius: 5px;
  transition: background 300ms, color 100ms;
  font-size: 18px;
  color: #000000;
  font-weight: 700;
}

.orange-gradinted-button:hover {
  background: linear-gradient(98.77deg, #FF9E44 -11.75%, #DC0854 103.61%);
  color: #ffffff;
}

.your-options {
  font-family: 'Montserrat', 'Arial', sans-serif;
  letter-spacing: -2%;
  line-height: 1.24em;
}

.your-options .basic-option__content {
  padding: 24px;
}

.your-options .basic-option__info {
  background: linear-gradient(99.78deg, #225C3A 0%, #10A52A 59.65%, #ACBB04 116.87%);
  padding: 18px 48px;
  color: #ffffff;
  border-radius: 16px;
}

.your-options .basic-option__title {
  margin-bottom: 7px;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.02em;
  font-weight: 700;
}

.your-options .basic-option__descr {
  font-size: 18px;
  letter-spacing: -0.02em;
  font-weight: 400;
  margin: 4px 0;
  line-height: 22px;
}

.your-options .basic-option__price {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: -0.02em;
  margin-top: 8px;
}

.your-options__to-settings {
  text-align: center;
}

.your-options__text {
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  margin-top: 24px;
}

.your-options__button {
  margin: 16px auto 0;
}

.your-options .additional-options {
  margin-top: 32px;
}

.your-options .additional-options__info {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 532px;
  margin-bottom: 24px;
}

.your-options .additional-options__image {
  width: 67px;
  margin-right: 13px;
}

.your-options .additional-options__header-title {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
}

.your-options .additional-options__header-text {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  margin-top: 8px;
}

.your-options .additional-options .additional-option {
  position: relative;
  width: 100%;
  border: 3px solid #B7B7B7;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  padding: 22px 80px 21px 80px;
  box-sizing: border-box;
}

.your-options .additional-options .additional-option:not(:last-child) {
  margin-bottom: 16px;
}

@media only screen and (max-width: 992px) {
  .your-options .additional-options .additional-option {
    padding: 16px 16px 16px 101px;
  }
}

.your-options .additional-options .additional-option__image {
  position: absolute;
  left: -52.5px;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 105px;
  height: 105px;
  background: linear-gradient(135deg, #225C3A 0%, #10A52A 51.04%, #ACBB04 100%);
  border-radius: 50%;
  transform: translateY(-50%);
}

.your-options .additional-options .additional-option__image svg {
  fill: #ffffff;
  font-size: 74px;
}

@media only screen and (max-width: 992px) {
  .your-options .additional-options .additional-option__image {
    top: 50%;
    left: 16px;
    width: 74px;
    height: 74px;
    transform: translateY(-50%);
  }
}

.your-options .additional-options .additional-option__title {
  color: #2E2E2E;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.02em;
  margin-bottom: 4px;
}

.your-options .additional-options .additional-option__description {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  color: #62656A;
  margin-bottom: 4px;
}

.your-options .additional-options .additional-option__price {
  margin: 0;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
  color: #62656A;
}

.your-options .additional-options .additional-option.is-active {
  background: linear-gradient(99.78deg, #225C3A 0%, #10A52A 59.65%, #ACBB04 116.87%);
  border: none;
}

.your-options .additional-options .additional-option.is-active .additional-option__title {
  color: #ffffff;
}

.your-options .additional-options .additional-option.is-active .additional-option__description {
  color: #ffffff;
}

.your-options .additional-options .additional-option.is-active .additional-option__price {
  color: #ffffff;
}

.accordion {
  max-width: 1048px;
}

.accordion__btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 26px 32px 26px 27px;
  width: 100%;
  background: linear-gradient(99.78deg, #225C3A 0%, #10A52A 59.65%, #ACBB04 116.87%);
  border-radius: 4px;
}

@media only screen and (max-width: 992px) {
  .accordion__btn {
    padding: 15px 24px;
  }
}

.accordion__btn:not(:last-child) {
  margin-bottom: 14px;
}

.accordion__btn svg {
  fill: #ffffff;
  font-size: 24px;
  transition: transform 300ms ease-in-out;
}

@media only screen and (max-width: 992px) {
  .accordion__btn svg {
    font-size: 22px;
  }
}

.accordion__btn.is-open + .accordion__inner {
  display: block;
  opacity: 1;
}

.accordion__btn.is-open svg {
  transform: rotate(90deg);
}

.accordion__btn-text {
  font-size: 24px;
  line-height: 1.2em;
  font-weight: 600;
  letter-spacing: -0.02em;
  color: #ffffff;
}

@media only screen and (max-width: 992px) {
  .accordion__btn-text {
    font-size: 18px;
  }
}

.accordion__inner {
  margin: 24px 0 40px;
  display: none;
  width: 100%;
  opacity: 0;
  transition: opacity 300ms;
}

@media only screen and (max-width: 992px) {
  .accordion__inner {
    margin: 16px 0 24px;
  }
}

.accordion__inner ul {
  padding: 0;
}

.accordion__inner ul li:not(:last-child) {
  margin-bottom: 24px;
}

@media only screen and (max-width: 992px) {
  .accordion__inner ul li:not(:last-child) {
    margin-bottom: 16px;
  }
}

.accordion__inner ul li h3 {
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.04em;
  color: #000000;
}

@media only screen and (max-width: 992px) {
  .accordion__inner ul li h3 {
    font-size: 18px;
  }
}

.accordion__inner ul li p {
  font-weight: 500;
  font-size: 24px;
  line-height: 1.22em;
  color: #000000;
}

@media only screen and (max-width: 992px) {
  .accordion__inner ul li p {
    font-size: 18px;
  }
}

.footer {
  margin-top: auto;
  width: 100%;
  background-color: #080611;
}

.footer__content {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 32px 0;
}

@media only screen and (max-width: 1200px) {
  .footer__content {
    flex-direction: column;
  }
}

@media only screen and (max-width: 576px) {
  .footer__content {
    padding: 16px 0 24px;
  }
}

.footer__top {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .footer__top {
    display: block;
  }
}

.footer__buttons-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  .footer__buttons-wrap {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .footer__buttons-wrap {
    align-items: flex-end;
  }
}

.footer__buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 450px;
}

@media only screen and (max-width: 768px) {
  .footer__buttons {
    flex-direction: column;
  }
}

.footer__buttons .button {
  display: none;
}

@media only screen and (max-width: 992px) {
  .footer__buttons .button {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .footer__buttons .button {
    width: fit-content;
  }
}

@media only screen and (max-width: 576px) {
  .footer__buttons .button {
    font-size: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .footer__buttons .button:nth-child(1) {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 1200px) {
  .footer__buttons .button:nth-child(1) {
    display: block;
  }
}

.footer .language {
  display: none;
}

@media only screen and (max-width: 992px) {
  .footer .language {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .footer .language {
    margin-bottom: 9px;
  }
}

.footer .language__list {
  background-color: #080611;
}

.footer__sevice {
  display: none;
}

@media only screen and (max-width: 992px) {
  .footer__sevice {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
}

.footer__sevice .socials {
  align-items: center;
}

.footer__address {
  width: 100%;
  max-width: 230px;
  text-align: right;
}

@media only screen and (max-width: 1200px) {
  .footer__address {
    display: flex;
    justify-content: space-between;
    max-width: unset;
    margin-bottom: 24px;
    text-align: left;
  }
}

.footer__address .contacts {
  display: none;
  text-align: left;
}

@media only screen and (max-width: 992px) {
  .footer__address .contacts {
    display: block;
  }
}

.footer__address-link {
  font-size: 18px;
  line-height: 1.33em;
  letter-spacing: -0.02em;
  font-weight: 600;
  text-decoration: none;
  color: #ffffff;
}

@media only screen and (max-width: 992px) {
  .footer__address-link {
    margin-right: 10px;
    font-size: 14px;
  }
}

.footer__address-link span {
  font-weight: 400;
}

.footer__bottom {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 576px) {
  .footer__bottom {
    flex-direction: column;
    align-items: flex-start;
  }
}

.footer__links ul {
  display: flex;
  padding: 0;
  margin: 0 0 24px;
}

@media only screen and (max-width: 992px) {
  .footer__links ul {
    flex-direction: column;
  }
}

.footer__links ul li:not(:last-child) {
  margin-right: 32px;
}

.footer__links ul li a {
  font-size: 16px;
  line-height: 1.22em;
  letter-spacing: -0.02em;
  font-weight: 500;
  color: #ffffff;
}

@media only screen and (max-width: 1400px) {
  .footer__links ul li a {
    font-size: 14px;
  }
}

.footer__copyright {
  max-width: 1067px;
  margin: 0 10px 0 0;
  font-family: 'Montserrat', 'Arial', sans-serif;
  font-size: 14px;
  line-height: 1.71em;
  font-weight: 500;
  color: #B7B7B7;
}

@media only screen and (max-width: 576px) {
  .footer__copyright {
    order: 2;
    margin: 0;
  }
}

.insurance-tab.is-unsubscribed .for-subscribed {
  display: none;
}

.insurance-tab.is-unsubscribed .for-unsubscribed {
  display: flex;
}

.insurance-tab.is-unsubscribed .options__header__info_title {
  color: #62656A;
}

.insurance-tab.is-unsubscribed .options__header__info_description {
  color: #B7B7B7;
}

.insurance-tab.is-unsubscribed .options__data_basic {
  color: #000000;
}

.insurance-tab.is-unsubscribed .options__data_basic::before {
  top: 3px;
  left: 3px;
  z-index: 2;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  border-radius: 16px;
  background-color: #ffffff;
}

@media only screen and (max-width: 1200px) {
  .insurance-tab.is-unsubscribed .options__data_basic .options__data__info_description {
    grid-column: 1/3;
    grid-row: 3/4;
  }
}

@media only screen and (max-width: 1200px) {
  .insurance-tab.is-unsubscribed .options__data_basic .options__data__info_price {
    margin: 0 0 4px;
    grid-row: 2/3;
  }
}

@media only screen and (max-width: 1200px) {
  .insurance-tab.is-unsubscribed .options__data_basic .options__data__info_button {
    grid-column: 1/3;
    grid-row: 4/5;
    margin: 12px 0;
    max-width: 400px;
  }
}

.insurance-tab.is-unsubscribed .options__data_add {
  pointer-events: none;
}

@media only screen and (min-width: 1201px) {
  .insurance-tab.is-unsubscribed .options__data__info {
    grid-template-columns: 1fr 37.643%;
  }
}

@media only screen and (min-width: 1201px) {
  .insurance-tab.is-unsubscribed .options__data__info_description {
    grid-column: 1/2;
    align-items: center;
  }
}

.insurance-tab.is-unsubscribed .options__data__info_button {
  grid-column: auto;
  width: 100%;
}

.insurance-tab.is-unsubscribed .options__data__info__button {
  background: transparent;
  border: 1px solid #B7B7B7;
}

.insurance-tab.is-unsubscribed .options__data__info__button span {
  color: #62656A;
}

.insurance-tab .for-unsubscribed {
  display: none;
}

.insurance-tab .tab-content {
  color: #00122D;
}

.insurance-tab .tab-content .tab_title {
  margin-top: 0;
  padding-left: 25px;
  font-weight: 600;
  font-size: 36px;
  line-height: 48px;
}

@media only screen and (max-width: 1200px) {
  .insurance-tab .tab-content .tab_title {
    padding: 0;
    font-size: 24px;
  }
}

.insurance-tab .tab-content.no-padding {
  padding-left: 0px;
}

.options {
  padding-left: 46px;
}

@media only screen and (max-width: 1200px) {
  .options {
    padding: 0;
  }
}

.options__header {
  position: relative;
  margin: 24px 0 16px;
  padding-left: 91px;
}

@media only screen and (max-width: 1200px) {
  .options__header {
    margin: 16px 0 8px;
    padding: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .options__header.has-image {
    display: grid;
    grid-template-columns: 44px 1fr;
    grid-column-gap: 8px;
  }
}

@media only screen and (max-width: 1200px) {
  .options__header.has-image .options__header__image {
    margin: 16px 0;
  }
}

@media only screen and (max-width: 1200px) {
  .options__header.has-image .options__header__info {
    grid-column: 1/3;
    grid-row: 1/4;
    display: grid;
    grid-template-columns: 44px 1fr;
    grid-template-rows: auto 44px 1fr;
    grid-column-gap: 8px;
  }
}

@media only screen and (max-width: 1200px) {
  .options__header.has-image .options__header__info_title {
    margin-top: 8px;
    font-size: 24px;
    line-height: 29px;
  }
}

@media only screen and (max-width: 1200px) {
  .options__header.has-image .options__header__info_description {
    margin: 16px 0;
  }
}

.options__header__image {
  position: absolute;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1200px) {
  .options__header__image {
    grid-column: 1/2;
    grid-row: 2/3;
    height: 100%;
    position: unset;
    transform: unset;
  }
}

.options__header__image img {
  width: 67px;
}

@media only screen and (max-width: 1200px) {
  .options__header__image img {
    width: 44px;
  }
}

.options__header__info_base, .options__header__info_title {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.02em;
  color: #000000;
}

@media only screen and (max-width: 1200px) {
  .options__header__info_base, .options__header__info_title {
    grid-column: 1/3;
    grid-row: 1/2;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }
}

.options__header__info_description {
  margin: 8px 0 24px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #00122D;
}

@media only screen and (max-width: 1200px) {
  .options__header__info_description {
    grid-column: 2/3;
    grid-row: 2/4;
    font-size: 14px;
    line-height: 17px;
  }
}

.options__header__info_description b {
  font-weight: 700;
}

@media only screen and (max-width: 1200px) {
  .options__header__info_description b {
    display: block;
  }
}

.options__data {
  padding: 24px 29px 24px 91px;
  position: relative;
  background: linear-gradient(99.78deg, #225C3A 0%, #10A52A 59.65%, #ACBB04 116.87%);
  border-radius: 16px;
}

.options__data .img-for-unsubscribed {
  display: flex;
}

.options__data .img-for-subscribed {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .options__data {
    padding: 14px 16px 2px;
    display: grid;
    grid-template-columns: 74px 1fr;
    grid-column-gap: 14px;
    grid-row-gap: 8px;
  }
}

.options__data:not(:last-child) {
  margin-bottom: 24px;
}

@media only screen and (max-width: 1200px) {
  .options__data:not(:last-child) {
    margin-bottom: 16px;
  }
}

.options__data_basic {
  color: #ffffff;
}

.options__data_basic .options__data__info_title {
  margin-bottom: 8px;
}

@media only screen and (max-width: 1200px) {
  .options__data_basic .options__data__info_price {
    margin-bottom: 12px;
  }
}

.options__data_add {
  color: #62656A;
}

.options__data_add::before {
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background-color: #ffffff;
  border: 3px solid #B7B7B7;
}

.options__data_add.has-active .img-for-unsubscribed {
  display: none;
}

.options__data_add.has-active .img-for-subscribed {
  display: flex;
}

.options__data_add.has-active::before {
  top: 3px;
  left: 3px;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  border: none;
}

.options__data_add.has-active .options__data__info_title {
  color: #000000;
}

.options__data_add.has-active .options__data__info_price {
  color: #016C25;
}

@media only screen and (max-width: 1200px) {
  .options__data_add .options__data__info_image {
    grid-row: 1/4;
  }
}

@media only screen and (max-width: 1200px) {
  .options__data_add .options__data__info_title {
    min-height: unset;
  }
}

@media only screen and (max-width: 1200px) {
  .options__data_add .options__data__info_description {
    grid-column: 2/3;
    grid-row: 2/3;
  }
}

.options__data_add .options__data__info_price {
  color: #B7B7B7;
}

@media only screen and (max-width: 1200px) {
  .options__data_add .options__data__info_price {
    grid-column: 2/3;
    grid-row: 3/4;
    margin-top: 4px;
  }
}

.options__data__image {
  position: absolute;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 22px;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1200px) {
  .options__data__image {
    grid-column: 1/2;
    grid-row: 1/2;
    position: unset;
    transform: unset;
  }
}

.options__data__image img {
  width: 105px;
}

@media only screen and (max-width: 1200px) {
  .options__data__image img {
    width: 74px;
  }
}

.options__data__image img.bigger-image {
  width: 83px;
}

.options__data__info {
  position: relative;
  z-index: 3;
  display: grid;
  grid-template-columns: 1fr 29.643%;
  grid-column-gap: 5%;
}

@media only screen and (max-width: 1200px) {
  .options__data__info {
    grid-column: 1/3;
    grid-row: 1/3;
    grid-template-columns: 74px 1fr;
    grid-template-rows: 1fr repeat(4, auto);
    grid-column-gap: 14px;
    grid-row-gap: 4px;
  }
}

.options__data__info_title {
  grid-column: 1/2;
  grid-row: 1/2;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.02em;
  transition: all .3s ease-in-out;
}

@media only screen and (max-width: 1200px) {
  .options__data__info_title {
    grid-column: 2/3;
    grid-row: 1/2;
    min-height: 74px;
  }
}

.options__data__info_description, .options__data__info_link, .options__data__info_details {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.02em;
}

@media only screen and (max-width: 1200px) {
  .options__data__info_description, .options__data__info_link, .options__data__info_details {
    font-size: 14px;
    line-height: 22px;
  }
}

.options__data__info_description {
  grid-column: 1/3;
  grid-row: 2/3;
}

.options__data__info_link, .options__data__info_buttons {
  grid-column: 1/3;
  grid-row: auto;
}

.options__data__info_link {
  margin-top: 4px;
  width: fit-content;
  text-decoration: underline;
  text-align: left;
  color: inherit;
  background-color: transparent;
}

.options__data__info_buttons {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1200px) {
  .options__data__info_buttons {
    margin-top: 12px;
  }
}

.options__data__info__button:not(:last-child) {
  margin-right: 16px;
}

@media only screen and (max-width: 1200px) {
  .options__data__info__button {
    margin: 0 16px 16px 0;
  }
}

.options__data__info_price {
  grid-column: 2/3;
  grid-row: 1/2;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.02em;
  text-align: right;
  transition: all .3s ease-in-out;
}

@media only screen and (max-width: 1200px) {
  .options__data__info_price {
    grid-column: 1/3;
    grid-row: 4/5;
    margin-top: 16px;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
  }
}

.options__data__info_details {
  grid-column: 1/3;
  grid-row: auto;
  color: #000000;
}

.options__data__info_details .unsubscribe-btn {
  display: none;
  margin-top: 24px;
  text-decoration: underline;
  color: #016C25;
  cursor: pointer;
}

.options__data__info_details .gradient-btn {
  padding: 17px 42px;
  background: linear-gradient(99.78deg, #ff9e44 0%, #dc0854 100%);
  border-radius: 10px;
  color: #ffffff;
}

.options__data__info .sms-form {
  margin-top: 24px;
  margin-bottom: 16px;
}

@media only screen and (max-width: 768px) {
  .options__data__info .sms-form {
    margin-top: 16px;
    margin-bottom: 8px;
  }
}

.options__data__info .sms-form .input-wrapp {
  display: none;
}

.options__data__info .sms-form .input-wrapp .resending {
  margin-bottom: 16px;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 600;
  color: #016C25;
  cursor: pointer;
}

.options__data__info .sms-form .input-wrapp .input-sms {
  border: 1px solid #B7B7B7;
}

.options__data__info .sms-form .input-wrapp .input-sms .input {
  border-bottom: 1px solid #C4C4C4;
}

.options__data__info .sms-form .input-wrapp .valid {
  border: 1px solid #009533;
}

.options__data__info .sms-form .input-wrapp .valid .input {
  border-bottom: 0;
}

.options__data__info .sms-form .input-wrapp .invalide {
  border: 1px solid #e60e0e;
}

.options__data__info .sms-form .input-wrapp .invalide .input {
  border-bottom: 0;
}

.options__data__info .sms-form .input-wrapp .valid, .options__data__info .sms-form .input-wrapp .invalide, .options__data__info .sms-form .input-wrapp .input-sms {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 56px;
  width: 226px;
  margin-bottom: 8px;
  padding: 16px;
  border-radius: 8px;
  font-size: 12px;
}

@media only screen and (max-width: 1200px) {
  .options__data__info .sms-form p {
    font-size: 14px;
    line-height: 17px;
  }
}

.options__data__info .sms-form p.small {
  font-size: 14px;
  line-height: 17px;
}

@media only screen and (max-width: 1200px) {
  .options__data__info .sms-form p.small {
    font-size: 13px;
    line-height: 16px;
  }
}

.options__data__info .sms-form p:not(:last-child) {
  margin-bottom: 8px;
}

.options__data__info .sms-form b {
  font-weight: 700;
}

.options__data__info_tab {
  margin-top: 24px;
  display: none;
}

@media only screen and (max-width: 1200px) {
  .options__data__info_tab {
    margin-top: 0;
    margin-bottom: 18px;
  }
}

.options__data__info_tab.tab_is-active {
  display: block;
}

.billing-tab .tab-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.billing-tab .tab_title {
  margin-top: 0;
  padding-left: 25px;
  font-weight: 600;
  font-size: 36px;
  line-height: 48px;
}

@media only screen and (max-width: 1200px) {
  .billing-tab .tab_title {
    padding: 0;
    font-size: 24px;
  }
}

.sidebar-wrap {
  width: 299px;
  margin-right: 129px;
}

@media only screen and (max-width: 992px) {
  .sidebar-wrap {
    margin-right: 85px;
  }
}

@media only screen and (max-width: 768px) {
  .sidebar-wrap {
    width: 100%;
    margin-right: 0;
    margin-bottom: 16px;
  }
}

.sidebar-wrap__title {
  margin-bottom: 24px;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
}

@media only screen and (max-width: 1200px) {
  .sidebar-wrap__title {
    font-size: 32px;
  }
}

@media only screen and (max-width: 768px) {
  .sidebar-wrap__title {
    font-size: 24px;
  }
}

.sidebar-wrap__sidebar {
  width: 100%;
  max-height: 264px;
  margin-top: 4px;
  z-index: 1;
  padding: 24px 26px 0;
  background: linear-gradient(99.78deg, #225C3A 0%, #10A52A 59.65%, #ACBB04 116.87%);
  box-shadow: 0px 4px 7px rgba(95, 176, 23, 0.1);
  border-radius: 8px;
}

@media only screen and (max-width: 1200px) {
  .sidebar-wrap__sidebar {
    margin: 0 auto;
    width: 100%;
    max-width: 300px;
  }
}

@media only screen and (max-width: 768px) {
  .sidebar-wrap__sidebar {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 8px 16px;
  }
}

.sidebar-wrap__sidebar::before {
  top: 2px;
  left: 2px;
  z-index: 2;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border-radius: 8px;
  background-color: #ffffff;
}

.sidebar-wrap__sidebar__content {
  position: relative;
  z-index: 3;
}

@media only screen and (max-width: 575px) {
  .personal-cabinet {
    padding: 10px;
  }
}

.personal-cabinet__navigation .navigation-tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

@media only screen and (max-width: 768px) {
  .personal-cabinet__navigation .navigation-tabs {
    flex-direction: row;
  }
}

.personal-cabinet__navigation .navigation-tabs__tab {
  width: 100%;
  margin-bottom: 24px;
  min-width: 100%;
  font-size: 20px;
  line-height: 1;
}

@media only screen and (max-width: 768px) {
  .personal-cabinet__navigation .navigation-tabs__tab {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .personal-cabinet__navigation .navigation-tabs__tab {
    padding: 0 8px;
    width: 100%;
    min-width: unset;
    height: 37px;
  }
}

@media only screen and (max-width: 768px) {
  .personal-cabinet__navigation .navigation-tabs__tab:not(:last-child) {
    margin-right: 4px;
  }
}

@media only screen and (max-width: 1200px) {
  .personal-cabinet__navigation .navigation-tabs__tab span {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }
}

.personal-cabinet__navigation .navigation-tabs__tab.is-active {
  color: #ffffff;
}

.personal-cabinet__navigation .navigation-tabs__tab.is-active::before {
  background-color: transparent;
}

.personal-cabinet__navigation .navigation-tabs__tab::before {
  top: 1px;
  left: 1px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
}

.page-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
}

@media only screen and (max-width: 1200px) {
  .page-nav {
    height: 50px;
  }
}

@media only screen and (max-width: 768px) {
  .page-nav {
    justify-content: center;
    flex-wrap: wrap-reverse;
    height: 50%;
  }
}

.page-nav .orange-gradient-border {
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .page-nav .orange-gradient-border {
    min-width: 50%;
    margin-bottom: 24px;
  }
}

.page-nav .orange-gradinted-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 247px;
}

@media only screen and (max-width: 1200px) {
  .page-nav .orange-gradinted-button {
    min-width: 145px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .page-nav .orange-gradinted-button {
    min-width: 100%;
  }
}

.page-nav .tabs-car {
  display: flex;
  width: 75%;
  height: 100%;
  font-size: 16px;
}

@media only screen and (max-width: 768px) {
  .page-nav .tabs-car {
    justify-content: center;
    align-items: center;
  }
}

.page-nav .tabs-car button {
  margin-left: 24px;
}

@media only screen and (max-width: 1200px) {
  .page-nav .tabs-car button {
    height: 100%;
    margin-left: 0;
    margin-right: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .page-nav .tabs-car button {
    height: 37px;
    margin-left: 0;
    margin-right: 16px;
  }
}

@media only screen and (max-width: 1200px) {
  .page-nav .tabs-car button span {
    font-size: 14px;
  }
}

.tab {
  display: none;
  margin-bottom: 49px;
}

@media only screen and (max-width: 1200px) {
  .tab {
    margin-bottom: 43px;
  }
}

@media only screen and (max-width: 768px) {
  .tab {
    margin-bottom: 20px;
  }
}

.tab_is-active {
  display: block;
}

.tab .content {
  align-items: flex-start;
}

@media only screen and (min-width: 1400px) {
  .tab .content {
    grid-column-gap: 10%;
  }
}

@media only screen and (max-width: 1200px) {
  .tab .content {
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
  }
}

@media only screen and (max-width: 1200px) {
  .tab .content .personal-cabinet__sidebar {
    grid-row: 3/4;
  }
}

@media only screen and (max-width: 1200px) {
  .tab .content .tab-content {
    grid-row: 1/2;
  }
}

.tab-content {
  color: #00122D;
}

.tab_title {
  font-size: 36px;
  padding-left: 25px;
  margin-top: 25px;
}

.tab_title.no-padding {
  padding-left: 0px;
}

@media only screen and (max-width: 1200px) {
  .tab-content {
    margin: auto;
  }
}

.tab-content .personal-data-tab__content {
  width: 50%;
}

@media only screen and (max-width: 1200px) {
  .tab-content .personal-data-tab__content {
    width: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .tab-content .personal-data-tab__content button {
    width: 100%;
  }
}

.tab-content .green-gradient-border {
  border-radius: 8px;
  padding: 1px;
}

.tab-content .invalid {
  background: red;
  border-radius: 8px;
  padding: 1px;
}

.tab-content .personal-data-form {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 52px;
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .tab-content .personal-data-form {
    margin-bottom: 22px;
  }
}

.tab-content .personal-data-form__label {
  position: relative;
  top: 26px;
  padding-left: 16px;
  font-size: 12px;
  font-weight: 500;
}

.tab-content .personal-data-form__input {
  padding-top: 26px;
  padding-left: 16px;
  width: 100%;
  height: 56px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 700;
}

.tab-content .submit-button {
  width: 345px;
  height: 56px;
  padding: 0;
  font-size: 18px;
}

.accordion {
  max-width: 1048px;
  max-height: 1100px;
}

.accordion__btn-count {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 64px;
  border-bottom: 1px solid #C4C4C4;
  background: #ffffff;
}

.accordion__btn-count svg {
  fill: #000000;
  font-size: 24px;
  transition: transform 300ms ease-in-out;
}

.accordion__btn-count.is-open {
  border: 0;
}

.accordion__btn-count.is-open + .accordion__drop {
  display: block;
  opacity: 1;
}

.accordion__btn-count.is-open svg {
  transform: rotate(90deg);
  fill: #016C25;
}

.accordion__btn-count.is-open span {
  color: #016C25;
}

.accordion__btn-count-text {
  font-size: 18px;
  line-height: 1.2em;
  font-weight: 500;
  letter-spacing: -0.02em;
  color: #000000;
}

@media only screen and (max-width: 991px) {
  .accordion__btn-count-text {
    font-size: 14px;
  }
}

.accordion__drop {
  padding: 1px;
  display: none;
  width: 100%;
  height: 100%;
  background: linear-gradient(99.78deg, #225c3a 0%, #10a52a 59.65%, #acbb04 116.87%);
  border-radius: 16px;
  opacity: 0;
  transition: opacity 300ms;
}

.accordion__drop-content {
  width: 100%;
  height: 100%;
  padding: 32px;
  border-radius: 16px;
  background: #ffffff;
}

.accordion__additional-options {
  margin-left: 24px;
}

.accordion__additional-options__title {
  font-size: 18px;
  font-weight: 700;
}

.accordion__additional-options__text {
  font-size: 14px;
  font-weight: 600;
}

.personal-count-option {
  position: relative;
  width: 100%;
  padding: 1px;
  background: linear-gradient(135deg, #225C3A 0%, #10A52A 61.04%, #ACBB04 100%);
  border-radius: 16px;
  margin-bottom: 16px;
}

.personal-count-option__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 120px;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 16px;
}

@media only screen and (max-width: 557px) {
  .personal-count-option__inner {
    align-items: start;
    justify-content: start;
    flex-direction: column;
    padding: 10px;
  }
}

.personal-count-option__price-wrap {
  text-align: right;
}

@media only screen and (max-width: 557px) {
  .personal-count-option__price-wrap {
    text-align: left;
  }
}

.personal-count-option__name, .personal-count-option__price-info {
  margin-bottom: 8px;
  font-size: 18px;
  color: #000000;
}

@media only screen and (max-width: 1200px) {
  .personal-count-option__name, .personal-count-option__price-info {
    font-size: 14px;
  }
}

@media only screen and (max-width: 991px) {
  .personal-count-option__name, .personal-count-option__price-info {
    font-size: 12px;
  }
}

@media only screen and (max-width: 557px) {
  .personal-count-option__name, .personal-count-option__price-info {
    font-size: 12px;
  }
}

.personal-count-option__name {
  font-weight: 600;
}

.personal-count-option__price-info {
  font-weight: 500;
}

.personal-count-option__price, .personal-count-option__total-price {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}

@media only screen and (max-width: 1200px) {
  .personal-count-option__price, .personal-count-option__total-price {
    font-size: 12px;
  }
}

.personal-count-option__total-price {
  font-size: 24px;
}

@media only screen and (max-width: 1200px) {
  .personal-count-option__total-price {
    font-size: 16px;
  }
}

.personal-count-option_basic .personal-count-option__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  padding: 24px;
  background-color: #ffffff;
  border-radius: none;
}

@media only screen and (max-width: 557px) {
  .personal-count-option_basic .personal-count-option__inner {
    align-items: start;
    justify-content: start;
    flex-direction: column;
    padding: 5px;
  }
}

.personal-count-option_basic .personal-count-option__price-wrap {
  display: flex;
  align-items: center;
  width: 30%;
}

@media only screen and (max-width: 557px) {
  .personal-count-option_basic .personal-count-option__price-wrap {
    width: 50%;
  }
}

.personal-count-option_basic .personal-count-option__name-wrap {
  width: 55%;
}

.personal-count-option_basic .personal-count-option__name {
  font-size: 18px;
  font-weight: 500;
}

@media only screen and (max-width: 1200px) {
  .personal-count-option_basic .personal-count-option__name {
    font-size: 14px;
  }
}

@media only screen and (max-width: 991px) {
  .personal-count-option_basic .personal-count-option__name {
    font-size: 12px;
  }
}

@media only screen and (max-width: 557px) {
  .personal-count-option_basic .personal-count-option__name {
    font-size: 12px;
  }
}

.personal-count-option_basic .personal-count-option__price {
  font-size: 24px;
  font-weight: 600;
  color: #000000;
  text-align: right;
}

.personal-count-option_basic .personal-count-option__total-price {
  text-align: right;
}

@media only screen and (max-width: 1200px) {
  .personal-count-option_basic .personal-count-option__total-price {
    width: 100%;
    text-align: left;
    font-size: 16px;
  }
}

.partners {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  max-width: 415px;
  height: fit-content;
}

.partners a {
  text-decoration: none;
}

.partners a img {
  width: 63px;
  height: 57px;
  object-fit: contain;
  object-position: center;
}

@media only screen and (max-width: 576px) {
  .partners a img {
    width: 45px;
    height: 40px;
  }
}

.partners a svg {
  fill: #ffffff;
}

.partners a:nth-child(1) svg {
  font-size: 41px;
}

.partners a:nth-child(2) svg {
  font-size: 57px;
  fill: transparent;
  stroke: #ffffff;
}

.partners a:nth-child(3) svg {
  font-size: 35px;
}

.hero {
  position: relative;
  padding-top: 130px;
  background-image: url("../../img/hero-section/hero-img.jpg");
  background-position: 90% 12%;
  background-repeat: no-repeat;
  background-size: 105%;
}

.hero::after {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(21, 57, 36, 0.6);
  mix-blend-mode: darken;
}

.hero__content {
  position: relative;
  z-index: 50;
  padding: 36px 0 56px;
  width: 100%;
}

.hero__title {
  margin-bottom: 54px;
  font-size: 72px;
  line-height: 1.22em;
  letter-spacing: -0.02em;
  font-weight: 700;
  color: #ffffff;
}

.hero__inner {
  display: flex;
  justify-content: space-between;
}

.hero__benefits {
  display: flex;
  justify-content: space-between;
  margin-right: 30px;
  width: 100%;
  max-width: 767px;
}

.hero__benefits .benefit {
  position: relative;
  padding: 5px 0 0 27px;
}

.hero__benefits .benefit:nth-child(1) {
  margin-right: 40px;
}

.hero__benefits .benefit:nth-child(2) p {
  padding-left: 10px;
}

.hero__benefits .benefit span {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 48px;
  line-height: 1.22em;
  font-weight: 600;
  color: #ffffff;
}

.hero__benefits .benefit p {
  font-size: 18px;
  line-height: 1.66em;
  letter-spacing: -0.02em;
  font-weight: 700;
  color: #ffffff;
}

@media only screen and (max-width: 1200px) {
  .hero__title {
    font-size: 48px;
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 992px) {
  .hero {
    padding-top: 100px;
  }
  .hero__inner {
    flex-direction: column;
    align-items: center;
  }
  .hero__benefits {
    margin: 0 0 16px;
  }
}

@media only screen and (max-width: 768px) {
  .hero__content {
    padding: 0 0 32px;
  }
  .hero__title {
    font-size: 32px;
    margin-bottom: 16px;
  }
  .hero__benefits {
    flex-direction: column;
  }
  .hero__benefits .benefit {
    padding: 5px 0 0 36px;
  }
  .hero__benefits .benefit:nth-child(2) p {
    padding-left: 0;
  }
  .hero__benefits .benefit p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 576px) {
  .hero {
    padding-top: 100px;
    background-position: 45% 60%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .hero__title {
    font-size: 24px;
    margin-bottom: 24px;
  }
  .hero .partners {
    max-width: 319px;
  }
  .hero .partners a svg {
    font-size: 29px;
  }
}

.cost {
  padding: 45px 0 55px;
}

@media only screen and (max-width: 768px) {
  .cost {
    padding: 32px 0 40px;
  }
}

.cost__content {
  display: flex;
  justify-content: space-between;
}

.cost__content .vehicle-form {
  margin-right: 126px;
}

@media only screen and (max-width: 1400px) {
  .cost__content .vehicle-form,
  .cost__content .calculate-cost {
    margin-right: 80px;
  }
}

@media only screen and (max-width: 992px) {
  .cost__content .vehicle-form,
  .cost__content .calculate-cost {
    margin-right: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .cost__content .vehicle-form,
  .cost__content .calculate-cost {
    margin-right: 0;
  }
}

.vehicle-form {
  width: 100%;
  max-width: 542px;
}

.vehicle-form__title {
  margin-bottom: 37px;
  font-size: 48px;
  line-height: 1.22em;
  letter-spacing: -0.02em;
  color: #00122D;
  font-weight: 600;
}

@media only screen and (max-width: 1400px) {
  .vehicle-form__title {
    font-size: 32px;
  }
}

@media only screen and (max-width: 992px) {
  .vehicle-form__title {
    font-size: 24px;
  }
}

.vehicle-form .form__inputs {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}

.vehicle-form .form__inputs-row {
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
}

.vehicle-form .form__inputs-row .custom-select:first-child {
  margin-right: 40px;
}

.vehicle-form .form__input-wrap {
  display: flex;
  position: relative;
  width: 50%;
  min-height: 56px;
  border: 1px solid #B7B7B7;
  border-radius: 8px;
}

.vehicle-form .form__input-wrap:first-child {
  margin-right: 40px;
}

.vehicle-form .form__input-wrap:focus-within {
  border-color: #009533;
  box-shadow: 4px 4px 10px rgba(0, 149, 51, 0.1);
}

.vehicle-form .form__input-wrap.is-valid {
  border-color: #009533;
}

.vehicle-form .form__input-wrap.is-not-valid {
  border-color: #e60e0e;
}

.vehicle-form .form__label {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  padding: 8px 0 0 14px;
  font-size: 12px;
  line-height: 1.33em;
  font-weight: 500;
  color: #000000;
}

.vehicle-form .form__input {
  margin-top: auto;
  height: 36px;
  font-size: 12px;
  line-height: 1.33em;
  font-weight: 600;
  color: #000000;
  border: none;
  border-radius: 8px;
}

.vehicle-form .form__input:focus {
  box-shadow: none;
}

.vehicle-form .form__input::placeholder {
  color: #C4C4C4;
}

.vehicle-form .form__fixed-btn-wrap {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 500;
  display: none;
  width: 100%;
  padding: 16px;
  background-color: #ffffff;
}

@media only screen and (max-width: 768px) {
  .vehicle-form .form__fixed-btn-wrap {
    display: block;
  }
}

.vehicle-form .form__fixed-btn-wrap .submit-button {
  display: block;
  max-width: 516px;
  margin: 0 auto;
}

.insurance-options {
  max-width: 592px;
}

@media only screen and (max-width: 1200px) {
  .insurance-options {
    max-width: 430px;
  }
}

@media only screen and (max-width: 992px) {
  .insurance-options {
    max-width: 343px;
  }
}

@media only screen and (max-width: 768px) {
  .insurance-options {
    display: none;
  }
}

.insurance-options_mobile {
  display: none;
}

@media only screen and (max-width: 768px) {
  .insurance-options_mobile {
    display: block;
    max-width: 100%;
    margin-bottom: 40px;
  }
}

.insurance-options__title {
  margin-bottom: 32px;
  font-size: 30px;
  line-height: 1.22em;
  letter-spacing: -0.02em;
  font-weight: 700;
  color: #000000;
}

@media only screen and (max-width: 1200px) {
  .insurance-options__title {
    font-size: 24px;
    margin-bottom: 16px;
  }
}

.insurance-options__additional-info {
  position: relative;
  margin-bottom: 32px;
}

.insurance-options__additional-info .insurance-options__title {
  margin-bottom: 16px;
}

.insurance-options__plus-icon {
  position: absolute;
  left: 0;
  top: 64px;
}

@media only screen and (max-width: 1200px) {
  .insurance-options__plus-icon {
    top: 50px;
  }
}

.insurance-options__text {
  max-width: 437px;
  margin: 0 0 0 90px;
  font-size: 18px;
  line-height: 1.22em;
  letter-spacing: -0.02em;
  font-weight: 400;
  color: #000000;
}

.insurance-options .insurance-option:not(:last-child) {
  margin-bottom: 24px;
}

.insurance-options .insurance-option_basic {
  margin-bottom: 40px;
}

.insurance-option {
  position: relative;
  width: 100%;
  padding: 3px;
  background: linear-gradient(135deg, #225C3A 0%, #10A52A 61.04%, #ACBB04 100%);
  border-radius: 16px;
  box-shadow: 4px 4px 20px rgba(18, 97, 45, 0.1);
}

.insurance-option__inner {
  padding: 32px 60px 32px 82px;
  background-color: #ffffff;
  border-radius: 16px;
}

@media only screen and (max-width: 1200px) {
  .insurance-option__inner {
    padding: 16px 16px 16px 62px;
  }
}

@media only screen and (max-width: 992px) {
  .insurance-option__inner {
    padding: 14px 13px 16px 16px;
  }
}

.insurance-option__icon {
  position: absolute;
  left: -52.5px;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 105px;
  height: 105px;
  background: linear-gradient(135deg, #225C3A 0%, #10A52A 51.04%, #ACBB04 100%);
  border-radius: 50%;
  transform: translateY(-50%);
}

.insurance-option__icon svg {
  fill: #ffffff;
  font-size: 74px;
}

@media only screen and (max-width: 992px) {
  .insurance-option__icon {
    top: 16px;
    left: 16px;
    width: 74px;
    height: 74px;
    transform: translateY(0);
  }
}

.insurance-option__name, .insurance-option__price-info {
  margin-bottom: 4px;
  font-size: 18px;
  line-height: 1.22em;
  letter-spacing: -0.02em;
}

@media only screen and (max-width: 992px) {
  .insurance-option__name, .insurance-option__price-wrap {
    margin-left: 88px;
  }
}

.insurance-option__name {
  font-weight: 700;
  color: #2E2E2E;
}

.insurance-option__price-info {
  font-weight: 600;
  color: #62656A;
}

@media only screen and (max-width: 992px) {
  .insurance-option__price-info {
    margin-bottom: 8px;
    font-size: 14px;
  }
}

.insurance-option__price {
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 1.22em;
  letter-spacing: -0.02em;
  font-weight: 700;
  color: #016C25;
}

@media only screen and (max-width: 992px) {
  .insurance-option__price {
    font-size: 18px;
    margin-bottom: 8px;
  }
}

.insurance-option__descr {
  margin: 0;
  font-size: 14px;
  line-height: 1.43em;
  letter-spacing: -0.02em;
  font-weight: 600;
  color: #00122D;
}

.insurance-option_basic {
  box-shadow: none;
}

.insurance-option_basic .insurance-option__inner {
  padding: 48px 60px 48px 82px;
  background-color: transparent;
  border-radius: none;
}

@media only screen and (max-width: 1200px) {
  .insurance-option_basic .insurance-option__inner {
    padding: 16px 16px 16px 62px;
  }
}

@media only screen and (max-width: 992px) {
  .insurance-option_basic .insurance-option__inner {
    padding: 16px;
  }
}

.insurance-option_basic .insurance-option__icon {
  background: #ffffff;
  border: 3px solid #016C25;
}

.insurance-option_basic .insurance-option__icon svg {
  fill: #016C25;
}

.insurance-option_basic .insurance-option__price-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 11px;
}

@media only screen and (max-width: 1200px) {
  .insurance-option_basic .insurance-option__price-wrap {
    flex-direction: column;
    align-items: flex-start;
  }
}

.insurance-option_basic .insurance-option__name,
.insurance-option_basic .insurance-option__price-info,
.insurance-option_basic .insurance-option__price,
.insurance-option_basic .insurance-option__descr {
  color: #ffffff;
}

.insurance-option_basic .insurance-option__name {
  margin-bottom: 7px;
}

.insurance-option_basic .insurance-option__price-info {
  margin-right: 13px;
  font-weight: 500;
}

.insurance-option_basic .insurance-option__price-info,
.insurance-option_basic .insurance-option__price {
  margin-bottom: 0;
}

.sum-insured {
  width: 100%;
}

.sum-insured__title {
  margin-bottom: 16px;
  font-size: 28px;
  line-height: 1em;
  letter-spacing: -0.02em;
  font-weight: 700;
  color: #00122D;
}

.sum-insured__total {
  font-size: 30px;
  line-height: 1.22em;
  font-weight: 800;
  color: #009533;
}

@media only screen and (max-width: 992px) {
  .sum-insured__total {
    display: inline-block;
    margin-top: 8px;
  }
}

.sum-insured__descr {
  margin-bottom: 16px;
  width: fit-content;
  font-size: 18px;
  line-height: 1.22em;
  font-weight: 400;
  letter-spacing: -0.02em;
  color: #00122D;
}

.sum-insured__descr_underlined {
  border-bottom: 1px solid #009533;
}

.sum-insured__descr + h4 {
  margin-top: 48px;
}

@media only screen and (max-width: 992px) {
  .sum-insured__descr + h4 {
    margin: 24px 0 8px;
  }
}

.sum-insured__kasko-cost {
  display: inline-block;
  margin-bottom: 32px;
  font-size: 36px;
  line-height: 1.17em;
  letter-spacing: -0.02em;
  font-weight: 800;
  color: #009533;
}

@media only screen and (max-width: 992px) {
  .sum-insured__kasko-cost {
    margin-bottom: 40px;
  }
}

.sum-insured__notification {
  display: none;
  margin-top: 16px;
  text-align: center;
  font-size: 18px;
  line-height: 1.22em;
  letter-spacing: -0.02em;
  color: #00122D;
}

.submit-button_disabled + .sum-insured__notification {
  display: block;
}

.calculate-cost {
  position: relative;
  width: 100%;
  max-width: 542px;
}

.calculate-cost__title {
  margin-bottom: 32px;
  font-size: 36px;
  line-height: 1.22em;
  letter-spacing: -0.02em;
  color: #00122D;
  font-weight: 700;
}

@media only screen and (max-width: 1400px) {
  .calculate-cost__title {
    font-size: 32px;
  }
}

@media only screen and (max-width: 992px) {
  .calculate-cost__title {
    font-size: 24px;
  }
}

.calculate-cost .form__inputs {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
}

.calculate-cost .form__input-wrap {
  margin-bottom: 32px;
  display: flex;
  position: relative;
  width: 50%;
  min-height: 56px;
  border: 1px solid #B7B7B7;
  border-radius: 8px;
}

.calculate-cost .form__input-wrap:first-child {
  margin-right: 40px;
}

.calculate-cost .form__input-wrap:focus-within {
  border-color: #009533;
  box-shadow: 4px 4px 10px rgba(0, 149, 51, 0.1);
}

.calculate-cost .form__input-wrap.is-valid {
  border-color: #009533;
}

.calculate-cost .form__input-wrap.is-not-valid {
  border-color: #e60e0e;
}

.calculate-cost .form__input-wrap + .submit-button_calculate-cost {
  margin-bottom: 40px;
}

@media only screen and (max-width: 768px) {
  .calculate-cost .form__input-wrap + .submit-button {
    display: none;
  }
}

.calculate-cost .form__fixed-btn-wrap {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 500;
  display: none;
  width: 100%;
  padding: 16px;
  background-color: #ffffff;
}

@media only screen and (max-width: 768px) {
  .calculate-cost .form__fixed-btn-wrap {
    display: block;
  }
}

.calculate-cost .form__fixed-btn-wrap .submit-button {
  display: block;
  max-width: 516px;
  margin: 0 auto;
}

.calculate-cost .form__label {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  padding: 8px 0 0 14px;
  font-size: 12px;
  line-height: 1.33em;
  font-weight: 500;
  color: #000000;
}

.calculate-cost .form__input {
  margin-top: auto;
  height: 36px;
  font-weight: 700;
  font-size: 12px;
  line-height: 1.29em;
  border: none;
  border-radius: 8px;
}

.calculate-cost .form__input:focus {
  box-shadow: none;
}

.calculate-cost .form__input::placeholder {
  color: #C4C4C4;
}

.calculate-cost .submit-button.hidden {
  display: none;
}

.forms {
  width: 40%;
  margin-right: 25px;
}

@media only screen and (max-width: 983px) {
  .forms {
    width: 50%;
    margin-right: 35px;
  }
}

@media only screen and (max-width: 886px) {
  .forms {
    width: 100%;
    margin-right: 0;
  }
}

.subscribe {
  width: 45%;
  padding-top: 15px;
}

@media only screen and (max-width: 887px) {
  .subscribe {
    display: none;
  }
}

.about-car {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 40px;
}

.about-car__info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1200px) {
  .about-car__info {
    display: flex;
  }
}

.about-car__info .card {
  width: 45%;
  height: 56px;
  margin-bottom: 24px;
  padding: 8px 16px;
  background: #009533;
  border-radius: 8px;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
}

@media only screen and (max-width: 1024px) {
  .about-car__info .card {
    margin-bottom: 12px;
    padding: 4px 8px;
    font-size: 10px;
  }
}

.about-car__info .card__title {
  margin-bottom: 8px;
  font-weight: 500;
}

#vin {
  margin: 0;
  height: 100%;
}

#vin .input-wrap {
  margin: 0;
  border: 1px solid #009533;
}

#file {
  margin: 0;
}

#file .file-loader {
  margin: 0;
}

#inp-file {
  display: none;
}

.file-loader {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 56px;
  width: 100%;
  margin-bottom: 24px;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #009533;
  font-size: 12px;
}

.file-loader .preview {
  display: flex;
  max-width: 225px;
  height: 100%;
  padding: 2px;
  border-radius: 8px;
  border: 1px solid #009533;
}

.file-loader .preview p {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.file-loader .preview img {
  height: auto;
  width: 35%;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 12px;
}

.custom-tab {
  margin-left: 24px;
  margin-bottom: 5px;
  height: 20px;
  width: auto;
  background: 0;
  font-size: 12px;
}

.custom-tab.is-active {
  border-bottom: 2px solid #009533;
}

.input-file {
  display: flex;
  background: 0;
  text-align: left;
  text-decoration: underline;
  width: 100%;
}

.input-file svg {
  font-size: 16px;
  margin-left: 18px;
  fill: #ffffff;
  stroke: #016C25;
}

form {
  display: flex;
  flex-direction: column;
}

form .submit-button_toggleInput {
  margin-bottom: 48px;
}

form .input-wrap {
  border: 1px solid #B7B7B7;
}

form .input-wrap .input {
  border-bottom: 1px solid #C4C4C4;
}

form .valid {
  border: 1px solid #009533;
}

form .valid .input {
  border-bottom: 0;
}

form .invalid {
  border: 1px solid #e60e0e;
}

form .invalid .input {
  border-bottom: 0;
}

form .valid, form .invalid, form .input-wrap {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 56px;
  width: 100%;
  margin-bottom: 24px;
  padding: 16px;
  border-radius: 8px;
  font-size: 12px;
}

form .valid__title, form .invalid__title, form .input-wrap__title {
  margin-bottom: 24px;
  margin-top: 27px;
  font-size: 24px;
  font-weight: 600;
}

form .valid .input, form .invalid .input, form .input-wrap .input {
  width: 50%;
  font-weight: 700;
  background: #ffffff;
}

form .valid .label, form .invalid .label, form .input-wrap .label {
  position: relative;
  font-weight: 500;
  margin-bottom: 7px;
}

.custom-label {
  position: relative;
  margin: 0 16px;
}

@media only screen and (max-width: 1400px) {
  .custom-label {
    font-size: 14px;
    margin: 0 10px;
  }
}

.custom-label::before {
  position: absolute;
  left: 0;
  bottom: 0;
  content: '';
  height: 1px;
  width: 100%;
  background-color: #C4C4C4;
}

.first-agree {
  margin-top: 26px;
}

.agree-check {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.agree-check .checked {
  width: 29px;
  height: 29px;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #009533;
  background-size: 50% 50%;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  border-radius: 5px;
}

.agree-check .checked.check {
  background: 0;
  border: 1px solid #009533;
}

.insurance-company {
  margin-top: 24px;
  margin-bottom: 64px;
}

@media only screen and (max-width: 1200px) {
  .insurance-company {
    margin-bottom: 44px;
  }
}

.insurance-company .sponsors {
  position: relative;
  height: 74px;
  width: 25%;
  margin-right: 8px;
  background: 0;
  border: 1px solid #C4C4C4;
  border-radius: 8px;
  z-index: 100;
}

.insurance-company .sponsors.active {
  border: 3px solid #009533;
}

.insurance-company .sponsors svg {
  height: 50%;
  width: 100%;
  pointer-events: none;
}

.insurance-company .sponsors_img img {
  width: 85%;
  height: 76%;
  object-fit: contain;
  object-position: center;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(307deg) brightness(104%) contrast(104%);
  pointer-events: none;
}

.insurance-sum {
  margin-bottom: 16px;
  font-size: 28px;
  font-weight: 700;
}

.insurance-sum__priсe {
  color: #009533;
  font-size: 30px;
  font-weight: 800;
}

@media only screen and (max-width: 1290px) {
  .insurance-sum__priсe {
    font-size: 24px;
  }
}

@media only screen and (max-width: 524px) {
  .insurance-sum {
    font-size: 24px;
    font-weight: 800;
  }
}

.insurance-sum__month {
  color: #009533;
  font-size: 42px;
  font-weight: 800;
}

@media only screen and (max-width: 1290px) {
  .insurance-sum__month {
    font-size: 30px;
  }
}

.insurance-additional {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 22px;
}

.insurance-additional .underline {
  position: relative;
  width: 60%;
}

.insurance-additional .underline::before {
  position: absolute;
  left: 0;
  bottom: 0;
  content: '';
  height: 1px;
  width: 90%;
  background-color: #009533;
}

@media only screen and (max-width: 1400px) {
  .insurance-additional .underline {
    width: 70%;
    font-size: 14px;
  }
}

@media only screen and (max-width: 887px) {
  .insurance-additional .underline {
    width: 38%;
  }
}

@media only screen and (max-width: 767px) {
  .insurance-additional .underline {
    width: 50%;
  }
}

.total {
  height: 100px;
  width: 100%;
  margin-bottom: 16px;
}

.description {
  margin-top: 10px;
  margin-bottom: 32px;
  font-size: 14px;
  font-weight: 300;
}

.submit-button {
  margin-top: 24px;
}

.sms-input {
  display: none;
}

.sms-input.visible {
  display: block;
  margin-bottom: 32px;
}

.re-sms {
  text-decoration: underline;
  color: #016C25;
  font-size: 14px;
  font-weight: 600;
}

.modal.is-visible {
  display: flex;
}

.modal::before {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: #62656A;
  opacity: .5;
}

.modal__content {
  position: relative;
  margin: auto;
  padding: 84px 161px;
  width: 100%;
  max-width: 694px;
  background-color: #ffffff;
  border-radius: 16px;
}

@media only screen and (max-width: 1200px) {
  .modal__content {
    padding: 62px 2.1333vw 40px;
    max-width: 343px;
  }
}

.modal__title {
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: center;
}

@media only screen and (max-width: 1200px) {
  .modal__title {
    margin-bottom: 11px;
    font-size: 18px;
    line-height: 22px;
  }
}

.modal__label {
  margin-bottom: 32px;
  padding: 0;
  position: unset;
  top: unset;
}

@media only screen and (max-width: 1200px) {
  .modal__label {
    margin-bottom: 11px;
  }
}

.modal__input {
  width: 100%;
  height: 56px;
  background-color: transparent;
  border: 2px solid #009533;
  border-radius: 8px;
  box-shadow: 4px 4px 10px rgba(0, 149, 51, 0.1);
}

.modal__button {
  margin: 0 auto;
  min-width: 186px;
}

@media only screen and (max-width: 1200px) {
  .modal__button {
    width: 100%;
    height: 56px;
  }
}

.unsubscribe {
  padding: 242px 0 380px;
}

@media only screen and (max-width: 992px) {
  .unsubscribe {
    padding: 160px 0 280px;
  }
}

@media only screen and (max-width: 576px) {
  .unsubscribe {
    padding: 124px 0 120px;
  }
}

@media only screen and (max-width: 1200px) {
  .unsubscribe {
    margin: 32px 0;
  }
}

.unsubscribe__content {
  margin: 0 auto;
  max-width: 999px;
  text-align: center;
  color: #00122D;
}

.unsubscribe__title, .unsubscribe__text {
  font-size: 40px;
  line-height: 48px;
  font-feature-settings: 'ss01' on, 'ss08' on;
}

.unsubscribe__title {
  margin-bottom: 32px;
  font-weight: bold;
}

@media only screen and (max-width: 1200px) {
  .unsubscribe__title {
    margin-bottom: 8px;
    font-size: 24px;
    line-height: 32px;
  }
}

.unsubscribe__text {
  margin-bottom: 72px;
  font-weight: 600;
}

@media only screen and (max-width: 1200px) {
  .unsubscribe__text {
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 22px;
  }
}

.unsubscribe__button {
  margin: 0 auto;
  width: 100%;
  max-width: 542px;
}

.submit-button {
  display: inline-block;
  margin: 0;
  padding: 26px 0;
  width: 100%;
  font-size: 28px;
  line-height: 1.22em;
  letter-spacing: -0.02em;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
  border-radius: 10px;
  background: linear-gradient(135deg, #FF9E44 0%, #DC0854 100%);
  box-shadow: 2px 2px 10px rgba(238, 109, 125, 0.2);
}

@media only screen and (max-width: 992px) {
  .submit-button {
    font-size: 18px;
  }
}

.submit-button_disabled {
  pointer-events: none;
  background: #B7B7B7;
}

.custom-select {
  position: relative;
  height: 56px;
  width: 50%;
  border: 1px solid #B7B7B7;
  border-radius: 8px;
}

.custom-select.is-open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 2px solid #009533;
  border-bottom: 1px solid #009533;
}

.custom-select.is-open .custom-select__list {
  display: block;
  border: 2px solid #009533;
  border-top: 1px solid #009533;
}

.custom-select.is-open .custom-select__icon {
  transform: rotate(180deg);
}

.custom-select.is-valid {
  border-color: #009533;
}

.custom-select__label {
  position: absolute;
  top: 8px;
  left: 16px;
  z-index: 1000;
  pointer-events: none;
  font-size: 12px;
  line-height: 1.33em;
  font-weight: 500;
  color: #000000;
}

.custom-select .select2-selection,
.custom-select .select2,
.custom-select .select2-selection__rendered {
  height: 100%;
}

.custom-select .select2-container--default .select2-selection--single .select2-selection__rendered {
  padding: 30px 0 0 16px;
  font-size: 12px;
  line-height: 1.33em;
  font-weight: 600;
  color: #000000;
}

.custom-select .select2-selection {
  border: none;
  border-radius: 8px;
}

.custom-select .select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  font-size: 12px;
  line-height: 1.33em;
  font-weight: 600;
  color: #000000;
}

.custom-select .select2-container--default .select2-selection--multiple .select2-selection__choice {
  margin: 32px 0 0 16px;
  padding: 0;
  background: none;
  border: none;
}

.custom-select .select2-container--default .select2-selection--multiple .select2-selection__choice button {
  display: none;
}

.custom-select .custom-select .select2-container--default .select2-search--inline .select2-search__field,
.custom-select .select2-search .select2-search--inline {
  font-size: 12px;
  line-height: 1.33em;
  font-weight: 600;
  color: #000000;
}

.custom-select .select2-search .select2-search--inline {
  display: block;
  margin-top: 16px;
}

.custom-select .select2-container--default .select2-search--inline .select2-search__field {
  margin: 0 -1px;
}

.custom-select .select2-selection__arrow {
  margin: 15px 5px 0 0;
  z-index: 10000;
}

.select2-container .select2-results__options .select2-results__option--highlighted {
  color: #000000;
  background-color: rgba(1, 108, 37, 0.2);
}

.select2-container .select2-results__options li {
  padding: 4px 0 4px 16px;
  font-size: 12px;
  line-height: 1.33em;
  font-weight: 600;
  color: #000000;
}

.no-touch .select2-container .select2-results__options li:hover {
  color: #000000;
  background-color: rgba(1, 108, 37, 0.2);
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  position: absolute;
  top: -30px;
  left: 0;
  height: 34px;
  padding: 0 0 0 16px;
  font-size: 12px;
  line-height: 1.33em;
  font-weight: 600;
  color: #000000;
  border: none;
  border-radius: 8px;
}

.select2-dropdown {
  border: none;
}

.select2-search--dropdown {
  padding: 0;
}

.select2-container--open .select2-dropdown--below {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.select2-container--default .select2-results > .select2-results__options {
  position: relative;
  z-index: 10000;
  margin: 0 -2px;
  max-height: 180px;
  border: 2px solid #009533;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top: 1px solid #009533;
}

.select2-container--default .select2-results > .select2-results__options::-webkit-scrollbar {
  width: 5px;
  border-radius: 50%;
}

.select2-container--default .select2-results > .select2-results__options::-webkit-scrollbar-track {
  border-radius: 8px;
}

.select2-container--default .select2-results > .select2-results__options::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: #C4C4C4;
  border-radius: 8px;
}

.select2-container--default .select2-results > .select2-results__options::-webkit-scrollbar-track-piece {
  border-radius: 8px;
}

.insurance-case {
  padding: 170px 0 75px;
}

@media only screen and (max-width: 992px) {
  .insurance-case {
    padding: 132px 0 57px;
  }
}

@media only screen and (max-width: 576px) {
  .insurance-case {
    padding: 116px 0 57px;
  }
}

.insurance-case__title {
  font-family: 'MontserratAlternates', 'Arial', sans-serif;
  margin-bottom: 40px;
  font-size: 40px;
  line-height: 1.2em;
  font-weight: 600;
  color: #00122D;
}

@media only screen and (max-width: 1200px) {
  .insurance-case__title {
    font-size: 32px;
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 992px) {
  .insurance-case__title {
    margin-bottom: 16px;
    font-size: 24px;
  }
}

.personal-cabinet {
  display: flex;
  padding: 178px 0 0;
}

@media only screen and (max-width: 992px) {
  .personal-cabinet {
    padding: 124px 0 0;
  }
}

@media only screen and (max-width: 576px) {
  .personal-cabinet {
    padding: 94px 10px 10px;
  }
}

.personal-cabinet__content {
  display: flex;
}

@media only screen and (max-width: 768px) {
  .personal-cabinet__content {
    flex-direction: column;
    align-items: center;
  }
}

.personal-cabinet__content .content-wrap {
  width: 65%;
}

@media only screen and (max-width: 768px) {
  .personal-cabinet__content .content-wrap {
    width: 85%;
  }
}

.entry {
  padding: 242px 0 396px;
}

@media only screen and (max-width: 992px) {
  .entry {
    padding: 190px 0 250px;
  }
}

@media only screen and (max-width: 576px) {
  .entry {
    padding: 124px 0 99px;
  }
}

.entry_sms {
  padding: 242px 0 444px;
}

@media only screen and (max-width: 992px) {
  .entry_sms {
    padding: 190px 0 250px;
  }
}

@media only screen and (max-width: 576px) {
  .entry_sms {
    padding: 124px 0 121px;
  }
}

.entry__content {
  margin: 0 auto;
  max-width: 631px;
}

@media only screen and (max-width: 576px) {
  .entry__content {
    max-width: 343px;
  }
}

.entry__title {
  margin-bottom: 40px;
  text-align: center;
  font-family: 'MontserratAlternates', 'Arial', sans-serif;
  font-size: 40px;
  line-height: 1.1em;
  font-weight: 600;
  color: #00122D;
}

@media only screen and (max-width: 768px) {
  .entry__title {
    margin-bottom: 24px;
    font-size: 24px;
  }
}

@media only screen and (max-width: 576px) {
  .entry__title {
    font-size: 18px;
  }
}

.entry .entry-phone-form__input-wrap,
.entry .entry-sms-form__input-wrap {
  display: flex;
  margin: 0 auto 40px;
  width: 100%;
  max-width: 372px;
  border: 1px solid #B7B7B7;
  border-radius: 8px;
}

@media only screen and (max-width: 768px) {
  .entry .entry-phone-form__input-wrap,
  .entry .entry-sms-form__input-wrap {
    margin: 0 auto 24px;
  }
}

.entry .entry-phone-form__input-wrap:focus-within,
.entry .entry-sms-form__input-wrap:focus-within {
  border-color: #009533;
  box-shadow: 4px 4px 10px rgba(0, 149, 51, 0.1);
}

.entry .entry-phone-form__input,
.entry .entry-sms-form__input {
  padding: 19px 23px;
  font-size: 18px;
  line-height: 1em;
  font-weight: 600;
  color: #000000;
  border: none;
  border-radius: 8px;
}

.entry .entry-phone-form__input:focus,
.entry .entry-sms-form__input:focus {
  box-shadow: none;
}

.entry .entry-phone-form__submit-button,
.entry .entry-sms-form__submit-button {
  margin: 0 auto;
  max-width: 542px;
}

@media only screen and (max-width: 576px) {
  .entry .entry-phone-form__submit-button,
  .entry .entry-sms-form__submit-button {
    font-size: 18px;
  }
}

.insurance {
  width: 100%;
  padding: 178px 0 107px;
}

@media only screen and (max-width: 992px) {
  .insurance {
    padding: 130px 0 40px;
  }
}

@media only screen and (max-width: 576px) {
  .insurance {
    padding: 100px 0 16px;
  }
}

.insurance .content-title {
  margin-bottom: 25px;
  font-size: 30px;
  font-weight: 700;
}

@media only screen and (max-width: 1200px) {
  .insurance .content-title {
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: 600;
  }
}

@media only screen and (max-width: 887px) {
  .insurance .content-title {
    font-size: 18px;
    font-weight: 600;
  }
}

.insurance .before-btn {
  display: flex;
  align-items: center;
  margin-right: 15px;
  font-size: 48px;
  font-weight: 100;
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {
  .insurance .before-btn {
    font-size: 36px;
  }
}

.insurance__title {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  font-size: 48px;
  font-weight: 600;
}

@media only screen and (max-width: 1200px) {
  .insurance__title {
    font-size: 36px;
  }
}

@media only screen and (max-width: 887px) {
  .insurance__title {
    font-size: 28px;
  }
}

.insurance__content {
  display: flex;
  justify-content: space-between;
}

.terms-page {
  max-width: 1000px;
  margin-left: 89px;
  padding: 170px 0 86px;
}

.terms-page__title {
  margin-bottom: 30px;
  font-size: 40px;
  font-weight: 600;
}

.terms-page__content {
  font-size: 24px;
}
