.tab {
	display: none;
	margin-bottom: 49px;

	@include r(1200) {
		margin-bottom: 43px; }

	@include r(768) {
		margin-bottom: 20px; }

	&_is-active {
		display: block; }

	.content {
		align-items: flex-start;

		@include rmin(1400) {
			grid-column-gap: 10%; }

		@include r(1200) {
			grid-template-columns: 1fr;
			grid-row-gap: 16px; }

		.personal-cabinet__sidebar {
			@include r(1200) {
				grid-row: 3/4; } }

		.tab-content {
			@include r(1200) {
				grid-row: 1/2; } } }

	&-content {
		color: $midnight-color; }

	&_title {
		font-size: 36px;
		padding-left: 25px;
		margin-top: 25px;

		&.no-padding {
			padding-left: 0px; } } }
