.sidebar-wrap {
	width: 299px;
	margin-right: 129px;

	@include r(992) {
		margin-right: 85px; }

	@include r(768) {
		width: 100%;
		margin-right: 0;
		margin-bottom: 16px; }

	&__title {
		margin-bottom: 24px;
		font-size: 40px;
		font-weight: 600;
		text-align: center;

		@include r(1200) {
			font-size: 32px; }

		@include r(768) {
			font-size: 24px; } }

	&__sidebar {
		width: 100%;
		max-height: 264px;
		margin-top: 4px;
		@include pseudo;
		z-index: 1;
		padding: 24px 26px 0;
		background: $green-gradient-color;
		box-shadow: 0px 4px 7px rgba(95, 176, 23, 0.1);
		border-radius: 8px;

		@include r(1200) {
			margin: 0 auto;
			width: 100%;
			max-width: 300px; }

		@include r(768) {
			display: flex;
			align-items: center;
			height: 100%;
			padding: 8px 16px; }

		&::before {
			top: 2px;
			left: 2px;
			z-index: 2;
			width: calc(100% - 4px);
			height: calc(100% - 4px);
			border-radius: 8px;
			background-color: $white-color; }

		&__content {
			position: relative;
			z-index: 3; } } }
