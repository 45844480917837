.insurance-option {
  position: relative;
  width: 100%;
  padding: 3px;
  background: linear-gradient(135deg, #225C3A 0%, #10A52A 61.04%, #ACBB04 100%);
  border-radius: 16px;
  box-shadow: 4px 4px 20px rgba(18, 97, 45, 0.1);

  &__inner {
    padding: 32px 60px 32px 82px;
    background-color: $white-color;
    border-radius: 16px;
    @include r(1200) {
      padding: 16px 16px 16px 62px; }
    @include r(992) {
      padding: 14px 13px 16px 16px; } }


  &__icon {
    position: absolute;
    left: -52.5px;
    top: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 105px;
    height: 105px;
    background: linear-gradient(135deg, #225C3A 0%, #10A52A 51.04%, #ACBB04 100%);
    border-radius: 50%;
    transform: translateY(-50%);
    & svg {
      fill: $white-color;
      font-size: 74px; }
    @include r(992) {
      top: 16px;
      left: 16px;
      width: 74px;
      height: 74px;
      transform: translateY(0); } }

  &__name,
  &__price-info {
    margin-bottom: 4px;
    font-size: 18px;
    line-height: 1.22em;
    letter-spacing: -0.02em; }

  &__name,
  &__price-wrap {
    @include r(992) {
      margin-left: 88px; } }

  &__name {
    font-weight: 700;
    color: $mine-shaft; }

  &__price-info {
    font-weight: 600;
    color: $shuttle-gray;
    @include r(992) {
      margin-bottom: 8px;
      font-size: 14px; } }

  &__price {
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 1.22em;
    letter-spacing: -0.02em;
    font-weight: 700;
    color: $fun-green-color;
    @include r(992) {
      font-size: 18px;
      margin-bottom: 8px; } }

  &__descr {
    margin: 0;
    font-size: 14px;
    line-height: 1.43em;
    letter-spacing: -0.02em;
    font-weight: 600;
    color: $midnight-color; }

  &_basic {
    box-shadow: none;

    & .insurance-option__inner {
      padding: 48px 60px 48px 82px;
      background-color: transparent;
      border-radius: none;
      @include r(1200) {
        padding: 16px 16px 16px 62px; }
      @include r(992) {
        padding: 16px; } }

    & .insurance-option__icon {
      background: $white-color;
      border: 3px solid $fun-green-color;
      & svg {
        fill: $fun-green-color; } }

    & .insurance-option__price-wrap {
      display: flex;
      align-items: center;
      margin-bottom: 11px;
      @include r(1200) {
        flex-direction: column;
        align-items: flex-start; } }

    & .insurance-option__name,
    .insurance-option__price-info,
    .insurance-option__price,
    .insurance-option__descr {
      color: $white-color; }

    & .insurance-option__name {
      margin-bottom: 7px; }

    & .insurance-option__price-info {
      margin-right: 13px;
      font-weight: 500; }

    & .insurance-option__price-info,
    .insurance-option__price, {
      margin-bottom: 0; } } }
