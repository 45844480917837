$green-gradient-color: linear-gradient(99.78deg, #225C3A 0%, #10A52A 59.65%, #ACBB04 116.87%);

.button_gradient {
	@include pseudo;
	z-index: 1;
	width: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	outline: none;
	color: inherit;
	border-radius: 8px;

	span {
		position: relative;
		z-index: 3;
		font-weight: bold;
		line-height: 1;
		text-align: center; }

	&_green {
		background: $green-gradient-color;
		box-shadow: 4px 4px 10px rgba(0, 149, 51, 0.1);

		span {
			color: $black-color; }

		&.is-active {
			span {
				color: $white-color; } } }

	&_green-borders {
		background: $green-gradient-color;
		box-shadow: 4px 4px 10px rgba(0, 149, 51, 0.1);

		span {
			color: $black-color; }

		&::before {
			top: 3px;
			left: 3px;
			z-index: 2;
			width: calc(100% - 6px);
			height: calc(100% - 6px);
			border-radius: 8px;
			background-color: $white-color;
			transition: all .3s ease-in-out; }

		&.is-active,
		&:hover {
			span {
				color: $white-color; }

			&::before {
				background-color: transparent; } } }

	&_red {
		background: $red-gradient-color;
		box-shadow: 2px 2px 10px rgba(238, 109, 125, 0.2);

		&.is-active,
		&:hover {
			span {
				color: $black-color; }

			&::before {
				opacity: 1; } }

		span {
			color: $white-color; }

		&::before {
			top: 3px;
			left: 3px;
			z-index: 2;
			width: calc(100% - 6px);
			height: calc(100% - 6px);
			border-radius: 8px;
			background-color: $white-color;
			opacity: 0;
			transition: all .3s ease-in-out; } }

	&_red-borders {
		background: $red-gradient-color;
		box-shadow: 2px 2px 10px rgba(238, 109, 125, 0.2);

		&::before {
			top: 3px;
			left: 3px;
			z-index: 2;
			width: calc(100% - 6px);
			height: calc(100% - 6px);
			border-radius: 8px;
			background-color: $white-color;
			transition: all .3s ease-in-out; }

		&.is-active,
		&:hover {
			span {
				color: $white-color; }

			&::before {
				background-color: transparent; } } }

	&_m {
		padding: 0 20px;
		height: 56px;

		@include r(1200) {
			height: 62px; }

		span {
			font-size: 18px; } }

	&_s {
		padding: 0 16px;
		height: 33px;

		&::before {
			top: 1px;
			left: 1px;
			width: calc(100% - 2px);
			height: calc(100% - 2px); }

		span {
			font-weight: 600;
			font-size: 14px;
			letter-spacing: -0.02em; } } }
