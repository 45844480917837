.custom-select {
  position: relative;
  height: 56px;
  width: 50%;
  border: 1px solid $nobel-color;
  border-radius: 8px;
  &.is-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 2px solid $fun-green-light-color;
    border-bottom: 1px solid $fun-green-light-color;
    .custom-select__list {
      display: block;
      border: 2px solid $fun-green-light-color;
      border-top: 1px solid $fun-green-light-color; }
    .custom-select__icon {
      transform: rotate(180deg); } }
  &.is-valid {
    border-color: $fun-green-light-color; }

  &__label {
    position: absolute;
    top: 8px;
    left: 16px;
    z-index: 1000;
    pointer-events: none;
    font-size: 12px;
    line-height: 1.33em;
    font-weight: 500;
    color: $black-color; }


  // Customizing select2
  & .select2-selection,
  & .select2,
  & .select2-selection__rendered {
    height: 100%; }

  & .select2-container--default .select2-selection--single .select2-selection__rendered {
    padding: 30px 0 0 16px;
    font-size: 12px;
    line-height: 1.33em;
    font-weight: 600;
    color: $black-color; }

  & .select2-selection {
    border: none;
    border-radius: 8px; }

  & .select2-container--default .select2-selection--multiple .select2-selection__choice__display {
    font-size: 12px;
    line-height: 1.33em;
    font-weight: 600;
    color: $black-color; }

  & .select2-container--default .select2-selection--multiple .select2-selection__choice {
    margin: 32px 0 0 16px;
    padding: 0;
    background: none;
    border: none;
    button {
      display: none; } }

  & .custom-select .select2-container--default .select2-search--inline .select2-search__field,
  & .select2-search .select2-search--inline {
    font-size: 12px;
    line-height: 1.33em;
    font-weight: 600;
    color: $black-color; }

  & .select2-search .select2-search--inline {
    display: block;
    margin-top: 16px; }

  & .select2-container--default .select2-search--inline .select2-search__field {
    margin: 0 -1px; }

  & .select2-selection__arrow {
    margin: 15px 5px 0 0;
    z-index: 10000; } }

.select2-container {
  & .select2-results__options {
    & .select2-results__option--highlighted {
      color: $black-color;
      background-color: rgba(1, 108, 37, 0.2); }
    & li {
      padding: 4px 0 4px 16px;
      font-size: 12px;
      line-height: 1.33em;
      font-weight: 600;
      color: $black-color;
      @include hover {
        color: $black-color;
        background-color: rgba(1, 108, 37, 0.2); } } } }

// customizing input field
.select2-container--default .select2-search--dropdown .select2-search__field {
  position: absolute;
  top: -30px;
  left: 0;
  height: 34px;
  padding: 0 0 0 16px;
  font-size: 12px;
  line-height: 1.33em;
  font-weight: 600;
  color: $black-color;
  border: none;
  border-radius: 8px; }

// customizing list
.select2-dropdown {
  border: none; }

.select2-search--dropdown {
  padding: 0; }

.select2-container--open .select2-dropdown--below {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px; }

.select2-container--default .select2-results>.select2-results__options {
  position: relative;
  z-index: 10000;
  margin: 0 -2px;
  max-height: 180px;
  border: 2px solid $fun-green-light-color;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top: 1px solid $fun-green-light-color;
  @include scrollbar {
    width: 5px;
    border-radius: 50%; }
  @include scrollbar-track {
    border-radius: 8px; }
  @include scrollbar-thumb {
    width: 5px;
    background-color: $silver-color;
    border-radius: 8px; }
  @include scrollbar-track-piece {
    border-radius: 8px; } }
