@mixin setFont($alias, $name, $weight: 'normal', $style: 'normal') {
  @font-face {
    font-family: $alias;
    src: url("/fonts/" + $name + ".ttf") format("ttf");
    src: url("/fonts/" + $name + ".woff2") format("woff2");
    font-weight: $weight;
    font-style: $style;
    font-display: swap; } }

@include setFont('Montserrat', 'Montserrat-Light', 300);
@include setFont('Montserrat', 'Montserrat-Regular', 400);
@include setFont('Montserrat', 'Montserrat-Medium', 500);
@include setFont('Montserrat', 'Montserrat-SemiBold', 600);
@include setFont('Montserrat', 'Montserrat-Bold', 700);
@include setFont('Montserrat', 'Montserrat-ExtraBold', 800);

@include setFont('MontserratAlternates', 'MontserratAlternates-Light', 300);
@include setFont('MontserratAlternates', 'MontserratAlternates-Regular', 400);
@include setFont('MontserratAlternates', 'MontserratAlternates-Medium', 500);
@include setFont('MontserratAlternates', 'MontserratAlternates-SemiBold', 600);
@include setFont('MontserratAlternates', 'MontserratAlternates-Bold', 700);
@include setFont('MontserratAlternates', 'MontserratAlternates-ExtraBold', 800);

@mixin fMontserrat {
  font-family: 'Montserrat', 'Arial', sans-serif; }

@mixin fMontserratAlt {
  font-family: 'MontserratAlternates', 'Arial', sans-serif; }

body {
  @include fMontserrat; }

* {
  @include fMontserrat; }
