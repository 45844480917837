.terms-page {
  max-width: 1000px;
  margin-left: 89px;
  padding: 170px 0 86px;

  &__title {
    margin-bottom: 30px;
    font-size: 40px;
    font-weight: 600; }

  &__content {
    font-size: 24px; } }
