.sum-insured {
  width: 100%;

  &__title {
    margin-bottom: 16px;
    font-size: 28px;
    line-height: 1em;
    letter-spacing: -0.02em;
    font-weight: 700;
    color: $midnight-color; }

  &__total {
    font-size: 30px;
    line-height: 1.22em;
    font-weight: 800;
    color: $fun-green-light-color;
    @include r(992) {
      display: inline-block;
      margin-top: 8px; } }

  &__descr {
    margin-bottom: 16px;
    width: fit-content;
    font-size: 18px;
    line-height: 1.22em;
    font-weight: 400;
    letter-spacing: -0.02em;
    color: $midnight-color;
    &_underlined {
      border-bottom: 1px solid $fun-green-light-color; }

    & + h4 {
      margin-top: 48px;
      @include r(992) {
        margin: 24px 0 8px; } } }

  &__kasko-cost {
    display: inline-block;
    margin-bottom: 32px;
    font-size: 36px;
    line-height: 1.17em;
    letter-spacing: -0.02em;
    font-weight: 800;
    color: $fun-green-light-color;
    @include r(992) {
      margin-bottom: 40px; } }

  &__notification {
    display: none;
    margin-top: 16px;
    text-align: center;
    font-size: 18px;
    line-height: 1.22em;
    letter-spacing: -0.02em;
    color: $midnight-color; } }

.submit-button_disabled {
  & + .sum-insured__notification {
    display: block; } }
