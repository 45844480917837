.insurance {
  width: 100%;
  padding: 178px 0 107px;
  @include r(992) {
    padding: 130px 0 40px; }
  @include r(576) {
    padding: 100px 0 16px; }

  .content-title {
    margin-bottom: 25px;
    font-size: 30px;
    font-weight: 700;

    @include r(1200) {
      margin-bottom: 15px;
      font-size: 24px;
      font-weight: 600; }

    @include r(887) {
      font-size: 18px;
      font-weight: 600; } }


  .before-btn {
    display: flex;
    align-items: center;
    margin-right: 15px;
    font-size: 48px;
    font-weight: 100;
    cursor: pointer;

    @include r(1200) {
      font-size: 36px; } }

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    font-size: 48px;
    font-weight: 600;

    @include r(1200) {
      font-size: 36px; }

    @include r(887) {
      font-size: 28px; } }

  &__content {
    display: flex;
    justify-content: space-between; } }

