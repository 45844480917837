.about-car {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 40px;

  &__info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    @include r(1200) {
      display: flex; }

    .card {
      width: 45%;
      height: 56px;
      margin-bottom: 24px;
      padding: 8px 16px;
      background: $fun-green-light-color;
      border-radius: 8px;
      font-size: 12px;
      font-weight: bold;
      color: $white-color;

      @include r(1024) {
        margin-bottom: 12px;
        padding: 4px 8px;
        font-size: 10px; }

      &__title {
        margin-bottom: 8px;
        font-weight: 500; } } } }

