.receive-your-kasko {
  & .content__header {
    text-align: center;
    color: $midnight-color;
    @include r(992) {
      text-align: center;
      margin-bottom: 16px;
      font-size: 32px; }
    @include r(768) {
      font-size: 24px;
      margin-bottom: 8px; } }

  &__text {
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: -0.02em;
    color: $midnight-color;
    @include r(992) {
      max-width: 450px;
      margin: 0 auto;
      font-size: 18px; } }



  &__button {
    margin: 32px auto 0;
    @include r(992) {
      margin: 16px auto 0; } } }
