.unsubscribe {
  padding: 242px 0 380px;
  @include r(992) {
    padding: 160px 0 280px; }
  @include r(576) {
    padding: 124px 0 120px; }

  @include r(1200) {
    margin: 32px 0; }

  &__content {
    margin: 0 auto;
    max-width: 999px;
    text-align: center;
    color: $midnight-color; }

  &__title,
  &__text {
    font-size: 40px;
    line-height: 48px;
    font-feature-settings: 'ss01' on, 'ss08' on; }

  &__title {
    margin-bottom: 32px;
    font-weight: bold;

    @include r(1200) {
      margin-bottom: 8px;
      font-size: 24px;
      line-height: 32px; } }

  &__text {
    margin-bottom: 72px;
    font-weight: 600;

    @include r(1200) {
      margin-bottom: 16px;
      font-size: 18px;
      line-height: 22px; } }

  &__button {
    margin: 0 auto;
    width: 100%;
    max-width: 542px; } }
