.contacts {
  text-align: right;
  max-width: 157px;
  &__tel,
  p {
    margin: 0;
    @include fMontserrat;
    font-size: 16px;
    line-height: 1.22em;
    letter-spacing: -0.02em;
    font-weight: 500;
    color: $white-color; }

  &__tel {
    text-decoration: none;
    font-weight: 700; }

  @include r(576) {
    &__tel,
    p {
      font-size: 14px; } } }
