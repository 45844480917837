.hero {
  position: relative;
  padding-top: 130px;
  background-image: url('../../img/hero-section/hero-img.jpg');
  background-position: 90% 12%;
  background-repeat: no-repeat;
  background-size: 105%;
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(21, 57, 36, 0.6);
    mix-blend-mode: darken; }

  &__content {
    position: relative;
    z-index: 50;
    padding: 36px 0 56px;
    width: 100%; }

  &__title {
    margin-bottom: 54px;
    font-size: 72px;
    line-height: 1.22em;
    letter-spacing: -0.02em;
    font-weight: 700;
    color: $white-color; }

  &__inner {
    display: flex;
    justify-content: space-between; }

  &__benefits {
    display: flex;
    justify-content: space-between;
    margin-right: 30px;
    width: 100%;
    max-width: 767px;

    .benefit {
      position: relative;
      padding: 5px 0 0 27px;
      &:nth-child(1) {
        margin-right: 40px; }
      &:nth-child(2) {
        & p {
          padding-left: 10px; } }

      & span {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 48px;
        line-height: 1.22em;
        font-weight: 600;
        color: $white-color; }
      & p {
        font-size: 18px;
        line-height: 1.66em;
        letter-spacing: -0.02em;
        font-weight: 700;
        color: $white-color; } } }

  @include r(1200) {
    &__title {
      font-size: 48px;
      margin-bottom: 32px; } }

  @include r(992) {
    padding-top: 100px;

    &__inner {
      flex-direction: column;
      align-items: center; }

    &__benefits {
      margin: 0 0 16px; } }

  @include r(768) {
    &__content {
      padding: 0 0 32px; }

    &__title {
      font-size: 32px;
      margin-bottom: 16px; }

    &__benefits {
      flex-direction: column;
      .benefit {
        padding: 5px 0 0 36px;
        &:nth-child(2) {
          & p {
            padding-left: 0; } }
        & span {}

        & p {
          font-size: 14px; } } } }

  @include r(576) {
    padding-top: 100px;
    background-position: 45% 60%;
    background-repeat: no-repeat;
    background-size: cover;

    &__title {
      font-size: 24px;
      margin-bottom: 24px; }

    .partners {
      max-width: 319px;
      & a {
        & svg {
          font-size: 29px; } } } } }
