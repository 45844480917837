.insurance-options {
  max-width: 592px;
  @include r(1200) {
    max-width: 430px; }
  @include r(992) {
    max-width: 343px; }
  @include r(768) {
    display: none; }

  &_mobile {
    display: none;
    @include r(768) {
      display: block;
      max-width: 100%;
      margin-bottom: 40px; } }

  &__title {
    margin-bottom: 32px;
    font-size: 30px;
    line-height: 1.22em;
    letter-spacing: -0.02em;
    font-weight: 700;
    color: $black-color;
    @include r(1200) {
      font-size: 24px;
      margin-bottom: 16px; } }

  &__additional-info {
    position: relative;
    margin-bottom: 32px;
    & .insurance-options__title {
      margin-bottom: 16px; } }

  &__plus-icon {
    position: absolute;
    left: 0;
    top: 64px;
    @include r(1200) {
      top: 50px; } }

  &__text {
    max-width: 437px;
    margin: 0 0 0 90px;
    font-size: 18px;
    line-height: 1.22em;
    letter-spacing: -0.02em;
    font-weight: 400;
    color: $black-color; }

  & .insurance-option {
    &:not(:last-child) {
      margin-bottom: 24px; } }

  & .insurance-option_basic {
    margin-bottom: 40px; } }
