.language {
  position: relative;

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 51px;
    height: 33px;
    @include fMontserrat;
    font-size: 16px;
    line-height: 1.22em;
    letter-spacing: -0.02em;
    font-weight: 600;
    color: $white-color;
    border: 1px solid $white-color;
    border-radius: 6px;
    background: none; }

  &__arrow {
    display: flex;
    margin-left: 4px;
    width: 12px;
    & svg {
      fill: transparent;
      stroke: $white-color; } }

  &__list {
    display: none;
    position: absolute;
    top: 38px;
    left: 0;
    z-index: 100;
    padding: 0;
    max-width: 51px;
    border: 1px solid $white-color;
    border-radius: 6px;
    background-color: $green-haze;
    & .is-active {
      text-decoration: underline; } }

  &__item {
    text-align: center;
    & button {
      color: $white-color;
      border: none; } } }
