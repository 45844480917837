$white-color: #ffffff;
$black-color: #000000;
$pine-green-color: #017676;
$selective-yellow-color: #FFB800;
$porcelain-color: #F3F4F5;
$midnight-color: #00122D;
$panache-color: #E3F2E8;
$fun-green-color: #016C25;
$fun-green-light-color: #009533;
$dusty-gray-color: #9C9C9C;
$tundora-color: #484848;
$anache-color: #E3F2E8;
$mine-shaft: #2E2E2E;
$red-color: #e60e0e;
$shuttle-gray: #62656A;
$silver-color: #CBCBCB;
$nobel-color: #B7B7B7;
$border-green: #009533;
$green-haze: #009962;
$ebony-color: #080611;
$silver-color: #C4C4C4;
$green-gradient-color: linear-gradient(99.78deg, #225C3A 0%, #10A52A 51.04%, #ACBB04 100%);
$green-gradient-color-more: linear-gradient(99.78deg, #225C3A 0%, #10A52A 59.65%, #ACBB04 116.87%);
$red-gradient-color: linear-gradient(99.78deg, #FF9E44 0%, #DC0854 100%);
