.button {
  display: inline-block;
  min-width: 255px;
  padding: 11px 0 10px;
  text-decoration: none;
  text-align: center;
  font-size: 18px;
  line-height: 1.36em;
  letter-spacing: -0.02em;
  color: $midnight-color;
  font-weight: 700;
  background-color: $selective-yellow-color;
  border-radius: 3px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  border: 2px solid $selective-yellow-color;
  transition: background-color 300ms, color 300ms;
  @include hover {
    background-color: $white-color;
    color: black; }

  &_header,
  &_footer {
    padding: 12px 9px;
    min-width: 121px;
    color: $white-color;
    border: 1px solid $white-color;
    border-radius: 10px;
    background: none;
    box-shadow: none;
    @include hover {
      background-color: $white-color;
      color: $fun-green-color; } } }

