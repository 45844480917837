.insurance-sum {
  margin-bottom: 16px;
  font-size: 28px;
  font-weight: 700;

  &__priсe {
    color: $fun-green-light-color;
    font-size: 30px;
    font-weight: 800;

    @include r(1290) {
      font-size: 24px; } }

  @include r(524) {
    font-size: 24px;
    font-weight: 800; }

  &__month {
    color: $fun-green-light-color;
    font-size: 42px;
    font-weight: 800;

    @include r(1290) {
      font-size: 30px; } } }

.insurance-additional {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 22px;

  .underline {
    position: relative;
    width: 60%;
    &::before {
      position: absolute;
      left: 0;
      bottom: 0;
      content: '';
      height: 1px;
      width: 90%;
      background-color: $fun-green-light-color; }

    @include r(1400) {
      width: 70%;
      font-size: 14px; }

    @include r(887) {
      width: 38%; }

    @include r(767) {
      width: 50%; } } }


.total {
  height: 100px;
  width: 100%;
  margin-bottom: 16px; }

.description {
  margin-top: 10px;
  margin-bottom: 32px;
  font-size: 14px;
  font-weight: 300; }

.submit-button {
  margin-top: 24px; }

.sms-input {
  display: none;

  &.visible {
    display: block;
    margin-bottom: 32px; } }

.re-sms {
  text-decoration: underline;
  color: $fun-green-color;
  font-size: 14px;
  font-weight: 600; }
