.rangeSlider,
.rangeSlider__fill {
  display: block;
  border-radius: 10px; }
.rangeSlider {
  position: relative;
  top: 100%;
  transform: translateY(-50%);
  background: transparent; }
.rangeSlider__horizontal {
  height: 5px;
  width: 100%; }
.rangeSlider__vertical {
  height: 100%;
  width: 20px; }
.rangeSlider--disabled {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  opacity: 0.4; }
.rangeSlider__fill {
  background: $fun-green-light-color;
  position: absolute; }
.rangeSlider__fill__horizontal {
  height: 100%;
  top: 0;
  left: 0; }
.rangeSlider__fill__vertical {
  width: 100%;
  bottom: 0;
  left: 0; }
.rangeSlider__handle {
  border: 1px solid $fun-green-light-color;
  cursor: pointer;
  display: inline-block;
  width: 18px;
  height: 18px;
  position: absolute;
  background: $white-color;
  border-radius: 50%;
  &:after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $fun-green-light-color;
    border-radius: 50%; }
  &:active {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.12)); } }
.rangeSlider__handle__horizontal {
  top: -7px; }
.rangeSlider__handle__vertical {
  left: -10px;
  bottom: 0; }
input[type="range"]:focus {
  & + .rangeSlider {
    .rangeSlider__handle {
      box-shadow: 0 0 8px rgba(142, 68, 173, 0.9); } } }
.rangeSlider__buffer {
  position: absolute;
  top: 3px;
  height: 14px;
  background: #2c3e50;
  border-radius: 10px; }
