.logo {
  display: flex;
  text-decoration: none;
  align-items: center;
  @include fMontserratAlt;
  font-size: 36px;
  line-height: 1.22em;
  font-weight: 700;
  color: $white-color;
  @include hover {
    color: $white-color; } }
