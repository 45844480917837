.tab-content {

  @include r(1200) {
   margin: auto; }

  .personal-data-tab__content {
    width: 50%;
    @include r(1200) {
      width: 100%; }

    button {
      @include r(991) {
        width: 100%; } } }


  .green-gradient-border {
    border-radius: 8px;
    padding: 1px; }

  .invalid {
    background: red;
    border-radius: 8px;
    padding: 1px; }

  .personal-data-form {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 52px;
    width: 100%;

    @include r(1200) {
      margin-bottom: 22px; }

    &__label {
      position: relative;
      top: 26px;
      padding-left: 16px;
      font-size: 12px;
      font-weight: 500; }

    &__input {
      padding-top: 26px;
      padding-left: 16px;
      width: 100%;
      height: 56px;
      border-radius: 8px;
      font-size: 12px;
      font-weight: 700; } }

  .submit-button {
    width: 345px;
    height: 56px;
    padding: 0;
    font-size: 18px; } }
