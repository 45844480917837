.insurance-tab {
	// unsubscribed
	&.is-unsubscribed {

		.for-subscribed {
			display: none; }

		.for-unsubscribed {
			display: flex; }

		.options {
			&__header {
				&__info {
					&_title {
						color: $shuttle-gray; }

					&_description {
						color: $nobel-color; } } }

			&__data {
				&_basic {
					color: $black-color;

					&::before {
						top: 3px;
						left: 3px;
						z-index: 2;
						width: calc(100% - 6px);
						height: calc(100% - 6px);
						border-radius: 16px;
						background-color: $white-color; }

					.options__data__info {
						&_description {
							@include r(1200) {
								grid-column: 1/3;
								grid-row: 3/4; } }

						&_price {
							@include r(1200) {
								margin: 0 0 4px;
								grid-row: 2/3; } }


						&_button {
							@include r(1200) {
								grid-column: 1/3;
								grid-row: 4/5;
								margin: 12px 0;
								max-width: 400px; } } } }

				&_add {
					pointer-events: none; } }

			&__data__info {
				@include rmin(1201) {
					grid-template-columns: 1fr 37.643%; }

				&_description {
					@include rmin(1201) {
						grid-column: 1/2;
						align-items: center; } }

				&_button {
					grid-column: auto;
					width: 100%; }

				&__button {
					background: transparent;
					border: 1px solid $nobel-color;

					span {
						color: $shuttle-gray; } } } } }

	.for-unsubscribed {
		display: none; }

	// default
	.tab-content {
		color: $midnight-color;

		.tab_title {
			margin-top: 0;
			padding-left: 25px;
			font-weight: 600;
			font-size: 36px;
			line-height: 48px;

			@include r(1200) {
				padding: 0;
				font-size: 24px; } }

		&.no-padding {
			padding-left: 0px; } } }

.options {
	padding-left: 46px;

	@include r(1200) {
		padding: 0; }

	&__header {
		position: relative;
		margin: 24px 0 16px;
		padding-left: 91px;

		@include r(1200) {
			margin: 16px 0 8px;
			padding: 0; }

		&.has-image {
			@include r(1200) {
				display: grid;
				grid-template-columns: 44px 1fr;
				grid-column-gap: 8px; }

			.options__header__image {
				@include r(1200) {
					margin: 16px 0; } }

			.options__header__info {
				@include r(1200) {
					grid-column: 1/3;
					grid-row: 1/4;
					display: grid;
					grid-template-columns: 44px 1fr;
					grid-template-rows: auto 44px 1fr;
					grid-column-gap: 8px; }

				&_title {
					@include r(1200) {
						margin-top: 8px;
						font-size: 24px;
						line-height: 29px; } }

				&_description {
					@include r(1200) {
						margin: 16px 0; } } } }

		&__image {
			position: absolute;
			@include center(v);
			left: 0;
			display: flex;
			align-items: center;

			@include r(1200) {
				grid-column: 1/2;
				grid-row: 2/3;
				height: 100%;
				position: unset;
				transform: unset; }

			img {
				width: 67px;

				@include r(1200) {
					width: 44px; } } }

		&__info {
			&_base,&_title {
				font-weight: bold;
				font-size: 24px;
				line-height: 29px;
				letter-spacing: -0.02em;
				color: $black-color;

				@include r(1200) {
					grid-column: 1/3;
					grid-row: 1/2;
					font-weight: 600;
					font-size: 18px;
					line-height: 22px; } }

			&_description {
				margin: 8px 0 24px;
				font-weight: 500;
				font-size: 18px;
				line-height: 22px;
				color: $midnight-color;

				@include r(1200) {
					grid-column: 2/3;
					grid-row: 2/4;
					font-size: 14px;
					line-height: 17px; }

				b {
					font-weight: 700;

					@include r(1200) {
						display: block; } } } } }

	&__data {
		@include pseudo;
		padding: 24px 29px 24px 91px;
		position: relative;
		background: $green-gradient-color-more;
		border-radius: 16px;

		.img-for-unsubscribed {
			display: flex; }

		.img-for-subscribed {
			display: none; }

		@include r(1200) {
			padding: 14px 16px 2px;
			display: grid;
			grid-template-columns: 74px 1fr;
			//grid-template-rows: 74px 1fr
			grid-column-gap: 14px;
			grid-row-gap: 8px; }

		&:not(:last-child) {
			margin-bottom: 24px;

			@include r(1200) {
				margin-bottom: 16px; } }

		&_basic {
			color: $white-color;

			.options__data__info_title {
				margin-bottom: 8px; }

			.options__data__info_price {
				@include r(1200) {
					margin-bottom: 12px; } } }

		&_add {
			color: $shuttle-gray;

			&::before {
				top: 0;
				left: 0;
				z-index: 2;
				width: 100%;
				height: 100%;
				border-radius: 16px;
				background-color: $white-color;
				border: 3px solid $nobel-color; }

			&.has-active {

				.img-for-unsubscribed {
					display: none; }

				.img-for-subscribed {
					display: flex; }

				&::before {
					top: 3px;
					left: 3px;
					width: calc(100% - 6px);
					height: calc(100% - 6px);
					border: none; }

				.options__data__info_title {
					color: $black-color; }

				.options__data__info_price {
					color: $fun-green-color; } }

			.options__data__info_image {
				@include r(1200) {
					grid-row: 1/4; } }

			.options__data__info_title {
				@include r(1200) {
					min-height: unset; } }

			.options__data__info_description {
				@include r(1200) {
					grid-column: 2/3;
					grid-row: 2/3; } }

			.options__data__info_price {
				color: $nobel-color;

				@include r(1200) {
					grid-column: 2/3;
					grid-row: 3/4;
					margin-top: 4px; } } }

		&__image {
			position: absolute;
			@include center(h);
			top: 22px;
			left: 0;
			z-index: 3;
			display: flex;
			align-items: center;

			@include r(1200) {
				grid-column: 1/2;
				grid-row: 1/2;
				position: unset;
				transform: unset; }

			img {
				width: 105px;

				@include r(1200) {
					width: 74px; }

				&.bigger-image {
					width: 83px; } } }

		&__info {
			position: relative;
			z-index: 3;
			display: grid;
			grid-template-columns: 1fr 29.643%;
			grid-column-gap: 5%;

			@include r(1200) {
				grid-column: 1/3;
				grid-row: 1/3;
				grid-template-columns: 74px 1fr;
				grid-template-rows: 1fr repeat(4, auto);
				grid-column-gap: 14px;
				grid-row-gap: 4px; }

			&_title {
				grid-column: 1/2;
				grid-row: 1/2;
				font-weight: 600;
				font-size: 18px;
				line-height: 22px;
				letter-spacing: -0.02em;
				transition: all .3s ease-in-out;

				@include r(1200) {
					grid-column: 2/3;
					grid-row: 1/2;
					min-height: 74px; } }

			&_description,
			&_link,
			&_details {
				font-weight: 500;
				font-size: 18px;
				line-height: 22px;
				letter-spacing: -0.02em;

				@include r(1200) {
					font-size: 14px;
					line-height: 22px; } }

			&_description {
				grid-column: 1/3;
				grid-row: 2/3; }

			&_link,
			&_buttons {
				grid-column: 1/3;
				grid-row: auto; }

			&_link {
				margin-top: 4px;
				width: fit-content;
				text-decoration: underline;
				text-align: left;
				color: inherit;
				background-color: transparent; }

			&_buttons {
				margin-top: 16px;
				display: flex;
				flex-wrap: wrap;

				@include r(1200) {
					margin-top: 12px; } }

			&__button {
				&:not(:last-child) {
					margin-right: 16px; }

				@include r(1200) {
					margin: 0 16px 16px 0; } }

			&_price {
				grid-column: 2/3;
				grid-row: 1/2;
				font-weight: bold;
				font-size: 24px;
				line-height: 29px;
				letter-spacing: -0.02em;
				text-align: right;
				transition: all .3s ease-in-out;

				@include r(1200) {
					grid-column: 1/3;
					grid-row: 4/5;
					margin-top: 16px;
					font-size: 18px;
					line-height: 22px;
					text-align: left; } }

			&_details {
				grid-column: 1/3;
				grid-row: auto;
				color: $black-color;

				.unsubscribe-btn {
					display: none;
					margin-top: 24px;
					text-decoration: underline;
					color: $fun-green-color;
					cursor: pointer; }

				.gradient-btn {
					padding: 17px 42px;
					background: linear-gradient(99.78deg,#ff9e44 0%,#dc0854 100%);
					border-radius: 10px;
					color: $white-color; } }

			.sms-form {
				margin-top: 24px;
				margin-bottom: 16px;

				@include r(768) {
					margin-top: 16px;
					margin-bottom: 8px; }

				.input-wrapp {
					display: none;

					.resending {
						margin-bottom: 16px;
						text-decoration: underline;
						font-size: 14px;
						font-weight: 600;
						color: $fun-green-color;
						cursor: pointer; }

					.input-sms {
						border: 1px solid $nobel-color;
						.input {
							border-bottom: 1px solid $silver-color; } }

					.valid {
						border: 1px solid $fun-green-light-color;
						.input {
							border-bottom: 0; } }

					.invalide {
						border: 1px solid $red-color;
						.input {
							border-bottom: 0; } }

					.valid, .invalide, .input-sms {
						display: flex;
						justify-content: center;
						flex-direction: column;
						height: 56px;
						width: 226px;
						margin-bottom: 8px;
						padding: 16px;
						border-radius: 8px;
						font-size: 12px; } }

				p {
					@include r(1200) {
						font-size: 14px;
						line-height: 17px; }

					&.small {
						font-size: 14px;
						line-height: 17px;

						@include r(1200) {
							font-size: 13px;
							line-height: 16px; } }

					&:not(:last-child) {
						margin-bottom: 8px; } }

				b {
					font-weight: 700; } }

			&_tab {
				margin-top: 24px;
				display: none;

				@include r(1200) {
					margin-top: 0;
					margin-bottom: 18px; }

				&.tab_is-active {
					display: block; } } } } }
