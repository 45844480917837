.accordion {
  max-width: 1048px;

  &__btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 26px 32px 26px 27px;
    width: 100%;
    background: linear-gradient(99.78deg, #225C3A 0%, #10A52A 59.65%, #ACBB04 116.87%);
    border-radius: 4px;
    @include r(992) {
      padding: 15px 24px; }
    &:not(:last-child) {
      margin-bottom: 14px; }
    & svg {
      fill: $white-color;
      font-size: 24px;
      transition: transform 300ms ease-in-out;
      @include r(992) {
        font-size: 22px; } }


    &.is-open {
      & + .accordion__inner {
        display: block;
        opacity: 1; }
      & svg {
        transform: rotate(90deg); } } }

  &__btn-text {
    font-size: 24px;
    line-height: 1.2em;
    font-weight: 600;
    letter-spacing: -0.02em;
    color: $white-color;
    @include r(992) {
      font-size: 18px; } }

  &__inner {
    margin: 24px 0 40px;
    display: none;
    width: 100%;
    opacity: 0;
    transition: opacity 300ms;
    @include r(992) {
      margin: 16px 0 24px; }

    ul {
      padding: 0;
      li {
        &:not(:last-child) {
          margin-bottom: 24px;
          @include r(992) {
            margin-bottom: 16px; } }
        h3 {
          margin-bottom: 8px;
          font-weight: 600;
          font-size: 24px;
          line-height: 1.04em;
          color: $black-color;
          @include r(992) {
            font-size: 18px; } }
        p {
          font-weight: 500;
          font-size: 24px;
          line-height: 1.22em;
          color: $black-color;
          @include r(992) {
            font-size: 18px; } } } } } }

