.personal-cabinet {
  display: flex;
  padding: 178px 0 0;
  @include r(992) {
    padding: 124px 0 0; }
  @include r(576) {
    padding: 94px 10px 10px; }

  &__content {
    display: flex;

    @include r(768) {
      flex-direction: column;
      align-items: center; }

    .content-wrap {
      width: 65%;

      @include r(768) {
        width: 85%; } } } }
