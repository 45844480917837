.billing-tab {

  .tab-content {
    display: flex;
    justify-content: center;
    flex-direction: column; }

  .tab_title {
    margin-top: 0;
    padding-left: 25px;
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;

    @include r(1200) {
      padding: 0;
      font-size: 24px; } } }



