.submit-button {
  display: inline-block;
  margin: 0;
  padding: 26px 0;
  width: 100%;
  font-size: 28px;
  line-height: 1.22em;
  letter-spacing: -0.02em;
  font-weight: 700;
  text-align: center;
  color: $white-color;
  border-radius: 10px;
  background: linear-gradient(135deg,#FF9E44 0%, #DC0854 100%);
  box-shadow: 2px 2px 10px rgba(238, 109, 125, 0.2);
  @include r(992) {
    font-size: 18px; }

  &_disabled {
    pointer-events: none;
    background: $nobel-color; } }
