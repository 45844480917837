.modal {
	&.is-visible {
		display: flex; }

	&::before {
		display: block;
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		background-color: $shuttle-gray;
		opacity: .5; }

	&__content {
		position: relative;
		margin: auto;
		padding: 84px 161px;
		width: 100%;
		max-width: 694px;
		background-color: $white-color;
		border-radius: 16px;

		@include r(1200) {
			padding: 62px 2.1333vw 40px;
			max-width: 343px; } }

	&__title {
		margin-bottom: 24px;
		font-weight: 600;
		font-size: 24px;
		line-height: 28px;
		letter-spacing: -0.02em;
		text-align: center;

		@include r(1200) {
			margin-bottom: 11px;
			font-size: 18px;
			line-height: 22px; } }

	&__label {
		margin-bottom: 32px;
		padding: 0;
		position: unset;
		top: unset;

		@include r(1200) {
			margin-bottom: 11px; } }

	&__input {
		width: 100%;
		height: 56px;
		background-color: transparent;
		border: 2px solid $border-green;
		border-radius: 8px;
		box-shadow: 4px 4px 10px rgba(0, 149, 51, 0.1); }

	&__button {
		margin: 0 auto;
		min-width: 186px;

		@include r(1200) {
			width: 100%;
			height: 56px; } } }
