.header-main {
  width: 100%;
  height: 438px;
  background-image: linear-gradient(95.29deg, rgba(2, 110, 38, 0.74) 0%, rgba(2, 110, 38, 0) 99.98%, rgba(255, 255, 255, 0.74) 99.99%), url("../../img/header/busy-traffic.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &__content {
    padding-top: 48px; }

  & .logo {
    margin-left: 35px;
    margin: 0 0 30px 35px; }

  &__price {
    margin: 0 0 10px 35px;
    font-size: 36px;
    line-height: 1.36em;
    letter-spacing: 0.02em;
    color: $white-color;
    font-weight: 700; }

  &__benefits {
    margin-left: 35px;
    list-style-type: numeric;
    max-width: 577px; }

  &__benefit {
    font-size: 18px;
    line-height: 1.67em;
    letter-spacing: 0.02em;
    color: $white-color;
    font-weight: 700; } }
