.footer {
  margin-top: auto;
  width: 100%;
  background-color: $ebony-color;

  &__content {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 32px 0;
    @include r(1200) {
      flex-direction: column; }
    @include r(576) {
      padding: 16px 0 24px; } }

  &__top {
    display: none;
    @include r(1200) {
      display: block; } }

  &__buttons-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include r(1200) {
      margin-bottom: 20px; }
    @include r(768) {
      align-items: flex-end; } }

  &__buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 450px;
    @include r(768) {
      flex-direction: column; }

    .button {
      display: none;
      @include r(992) {
        display: block; }
      @include r(768) {
        width: fit-content; }
      @include r(576) {
        font-size: 16px; }
      &:nth-child(1) {
        @include r(768) {
          margin-bottom: 16px; } }
      &:nth-child(1) {
        @include r(1200) {
          display: block; } } } }

  .language {
    display: none;
    @include r(992) {
      display: block; }
    @include r(768) {
      margin-bottom: 9px; }
    &__list {
      background-color: $ebony-color; } }

  &__sevice {
    display: none;
    @include r(992) {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px; }
    .socials {
      align-items: center; } }

  &__address {
    width: 100%;
    max-width: 230px;
    text-align: right;
    @include r(1200) {
      display: flex;
      justify-content: space-between;
      max-width: unset;
      margin-bottom: 24px;
      text-align: left; }
    .contacts {
      display: none;
      text-align: left;
      @include r(992) {
        display: block; } } }

  &__address-link {
    font-size: 18px;
    line-height: 1.33em;
    letter-spacing: -0.02em;
    font-weight: 600;
    text-decoration: none;
    color: $white-color;
    @include r(992) {
      margin-right: 10px;
      font-size: 14px; }
    span {
      font-weight: 400; } }



  &__bottom {
    display: flex;
    flex-direction: column;
    @include r(576) {
      flex-direction: column;
      align-items: flex-start; } }

  &__links {
    ul {
      display: flex;
      padding: 0;
      margin: 0 0 24px;
      @include r(992) {
        flex-direction: column; }
      li {
        &:not(:last-child) {
         margin-right: 32px; }
        a {
          font-size: 16px;
          line-height: 1.22em;
          letter-spacing: -0.02em;
          font-weight: 500;
          color: $white-color;
          @include r(1400) {
            font-size: 14px; } } } } }

  &__copyright {
    max-width: 1067px;
    margin: 0 10px 0 0;
    @include fMontserrat;
    font-size: 14px;
    line-height: 1.71em;
    font-weight: 500;
    color: $nobel-color;
    @include r(576) {
      order: 2;
      margin: 0; } } }
