form {
    display: flex;
    flex-direction: column;

    .submit-button_toggleInput {
      margin-bottom: 48px; }

    .input-wrap {
      border: 1px solid $nobel-color;
      .input {
        border-bottom: 1px solid $silver-color; } }

    .valid {
      border: 1px solid $fun-green-light-color;
      .input {
        border-bottom: 0; } }

    .invalid {
      border: 1px solid $red-color;
      .input {
        border-bottom: 0; } }

    .valid, .invalid, .input-wrap {
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 56px;
      width: 100%;
      margin-bottom: 24px;
      padding: 16px;
      border-radius: 8px;
      font-size: 12px;

      &__title {
        margin-bottom: 24px;
        margin-top: 27px;
        font-size: 24px;
        font-weight: 600; }

      .input {
        width: 50%;
        font-weight: 700;
        background: $white-color; }

      .label {
        position: relative;

        font-weight: 500;
        margin-bottom: 7px; } } }

.custom-label {
  position: relative;
  margin: 0 16px;

  @include r(1400) {
    font-size: 14px;
    margin: 0 10px; }

  &::before {
    position: absolute;
    left: 0;
    bottom: 0;
    content: '';
    height: 1px;
    width: 100%;
    background-color: $silver-color; } }

.first-agree {
  margin-top: 26px; }

.agree-check {
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  .checked {
    width: 29px;
    height: 29px;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: $fun-green-light-color;
    background-size: 50% 50%;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    border-radius: 5px;


    &.check {
      background: 0;
      border: 1px solid $fun-green-light-color; } } }
