.page-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;

  @include r(1200) {
    height: 50px; }

  @include r(768) {
    justify-content: center;
    flex-wrap: wrap-reverse;
    height: 50%; }

  .orange-gradient-border {
    height: 100%;
    @include r(768) {
      min-width: 50%;
      margin-bottom: 24px; } }

  .orange-gradinted-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: 247px;

    @include r(1200) {
      min-width: 145px;
      font-size: 14px; }

    @include r(768) {
      min-width: 100%; } }

  .tabs-car {
    display: flex;
    width: 75%;
    height: 100%;
    font-size: 16px;

    @include r(768) {
      justify-content: center;
      align-items: center; }

    button {
      margin-left: 24px;

      @include r(1200) {
        height: 100%;
        margin-left: 0;
        margin-right: 16px; }

      @include r(768) {
        height: 37px;
        margin-left: 0;
        margin-right: 16px; }

      span {
        @include r(1200) {
          font-size: 14px; } } } } }

