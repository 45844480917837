.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 40px 0;
  min-height: 130px;
  background: linear-gradient(95.29deg, rgba(17, 114, 1, 0.963488) 0%, rgba(21, 160, 45, 0.974982) 51.04%, #009962 100%);

  &__content {
    display:  flex;
    justify-content: space-between; }

  &__left {
    display: flex;
    justify-content: space-between;
    margin-right: 15px;
    width: 100%;
    max-width: 725px;
    max-height: 50px; }

  &__right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 424px; }

  @include r(1400) {
    .button {
      font-size: 16px; } }

  @include r(1200) {
    .logo {
      font-size: 24px; }

    &__left {
      max-width: 350px;
      .button {
        &:nth-child(3) {
          display: none; } } } }

  @include r(992) {
    display: flex;
    align-items: center;
    padding: 30px 0;
    min-height: 100px;

    &__left {
      max-width: unset; }

    &__right {
      display: none; }

    .logo {
      font-size: 24px; }

    .button {
      &:nth-child(2) {
        padding: 7px 13px;
        font-size: 16px;
        line-height: 1.22em; } } }

  @include r(576) {
    padding: 24px 0;
    min-height: 84px;
    .logo {
      font-size: 18px; } }

  &_main {
    position: absolute;
    background: transparent; } }
