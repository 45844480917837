.vehicle-form {
  width: 100%;
  max-width: 542px;

  &__title {
    margin-bottom: 37px;
    font-size: 48px;
    line-height: 1.22em;
    letter-spacing: -0.02em;
    color: $midnight-color;
    font-weight: 600;
    @include r(1400) {
      font-size: 32px; }
    @include r(992) {
      font-size: 24px; } }

  & .form {
    &__inputs {
      display: flex;
      flex-direction: column;
      margin-bottom: 32px; }

    &__inputs-row {
      margin-bottom: 32px;
      display: flex;
      justify-content: space-between;
      & .custom-select {
        &:first-child {
          margin-right: 40px; } } }

    &__input-wrap {
      display: flex;
      position: relative;
      width: 50%;
      min-height: 56px;
      border: 1px solid $nobel-color;
      border-radius: 8px;
      &:first-child {
        margin-right: 40px; }
      &:focus-within {
        border-color: $fun-green-light-color;
        box-shadow: 4px 4px 10px rgba(0, 149, 51, 0.1); }
      &.is-valid {
        border-color: $fun-green-light-color; }
      &.is-not-valid {
        border-color: $red-color; } }

    &__label {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      padding: 8px 0 0 14px;
      font-size: 12px;
      line-height: 1.33em;
      font-weight: 500;
      color: $black-color; }

    &__input {
      margin-top: auto;
      height: 36px;
      font-size: 12px;
      line-height: 1.33em;
      font-weight: 600;
      color: $black-color;
      border: none;
      border-radius: 8px;
      &:focus {
        box-shadow: none; }

      &::placeholder {
        color: $silver-color; } }

    &__fixed-btn-wrap {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 500;
      display: none;
      width: 100%;
      padding: 16px;
      background-color: $white-color;
      @include r(768) {
        display: block; }
      & .submit-button {
        display: block;
        max-width: 516px;
        margin: 0 auto; } } } }
