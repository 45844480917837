.insurance-case {
  padding: 170px 0 75px;
  @include r(992) {
    padding: 132px 0 57px; }
  @include r(576) {
    padding: 116px 0 57px; }

  &__title {
    @include fMontserratAlt;
    margin-bottom: 40px;
    font-size: 40px;
    line-height: 1.2em;
    font-weight: 600;
    color: $midnight-color;
    @include r(1200) {
      font-size: 32px;
      margin-bottom: 24px; }
    @include r(992) {
      margin-bottom: 16px;
      font-size: 24px; } } }
